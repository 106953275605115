import React, { useEffect, useState  } from 'react';   
import Modal from 'react-modal'; 
import { useData } from '../config/DataContext';  
import { useNavigate, useLocation, json } from 'react-router-dom';
import ImagePopup from './ImagePopup';
import Alert from './Alert';
import { fi } from 'date-fns/locale';
 
Modal.setAppElement('#root');  

const MyCreationImages = ({UserIdQuery,PlatformQuery,  FilterArray, onDataFetchFilters,onDataCount, refresh})  => {
    const navigate  = useNavigate(); 
    const [dataAll, setDataAll] = useState([]);      
    const [dataAllFilter, setdataAllFilter] = useState([]);    
    const configData = useData();  
    const [imagePendingCount, setImagePendingCount] = useState(0);
    const [imagePublishedCount, setImagePublishedCount] = useState(0);
    const [isModalOpenImage, setisModalOpenImage] = useState(false);
    const [isModalImageId, setisModalImageId] = useState(0);
    const [isModalAlertOpen, setisModalAlertOpen] = useState(false);
    const [shouldOpenModal, setShouldOpenModal] = useState(false);
    const [isTitle, setisTitle] = useState('');
    const [isMessage, setisMessage] = useState('');
    const [isStatus, setisStatus] = useState('');
    const location = useLocation();
    let token = localStorage.getItem('Authorization');
    useEffect(() => {  
      const filteredData = FilterArray.length > 0
      ? dataAll.filter(item => FilterArray.includes(item.status))
      : dataAll;
     
      setdataAllFilter(filteredData);
      onDataCount(filteredData.length);
    
    }, [FilterArray]);
    
   
    const fetchData = async () => {
     // alert(FilterArray);
     
      try {
        let token = localStorage.getItem('Authorization');
        const response = await fetch(
          `${configData.apiUrl}Creation/GetCreations?platform=${PlatformQuery}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token 
            },
          }
        );
        const data = await response.json();      
        const statusNames = Array.from(new Set(data.map(item => item.status))); 
        onDataFetchFilters(statusNames); 
      
        const filteredData = FilterArray.length > 0
        ? data.filter(item => FilterArray.includes(item.status))
        : data;
       
        setDataAll(filteredData);
        onDataCount(filteredData.length); 
        setdataAllFilter(filteredData);
               // console.log(JSON.stringify(filteredData));
       
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => { 
      const publishedCount = dataAll.filter(creation => creation.status === 'Spotlight' || creation.status === 'Gallery' ).length;
      const pendingCount = dataAll.filter(creation => creation.status !== 'Spotlight' && creation.status !== 'Gallery').length;
  
      setImagePublishedCount(publishedCount);
      setImagePendingCount(pendingCount);
    }, [dataAll]);

    const refreshData = location.state?.refresh;

    useEffect(() => { 
       fetchData();  
    }, [refresh]);  
 
   

    useEffect(() => {
      if (shouldOpenModal) {
          openModalAlert();
          setShouldOpenModal(false);
      }
    }, [shouldOpenModal]);

    const openModalAlert = () => {
      setisModalAlertOpen(true);  
      setTimeout(() => {
        closeModalAlert();
      }, 3000);
    };

    const closeModalAlert = () => {
        setisModalAlertOpen(false); 
        
    };
     
    const handleUpdatePublishedClick = () => { 
      setisTitle('');
      setisStatus('Published');
      setisMessage('Any change or modification in the information or status of the published creations, please contact help@getyouniq.com');
      setShouldOpenModal(true);
    }
    
    const handleDraftClick = (id) => {   
      navigate('/MyStudioDetails', { state: { creationId : id, creationStatus :'Draft' } });
    }
    
    const handleAwaitingClick = (id) => {  
      navigate('/MyStudioDetails', { state: { creationId : id, creationStatus :'AwaitingApproval' } });
    }

    const handleChangeRequestedClick = (id) => {  
      navigate('/MyStudioDetails', { state: { creationId : id, creationStatus :'ChangeRequested' } });
    }
    
    const handleApprovedClick = (id) => {  
      navigate('/MyStudioDetails', { state: { creationId : id, creationStatus :'Approved' } });
    }
      
 
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleSpotlightClick = (linkUrl, status, shopifyLinkUrl ) => {  
    let userType = localStorage.getItem('userType'); 
    let userName = localStorage.getItem('userName');
    
    let userPhoto = localStorage.getItem('userPhoto');

    let token = localStorage.getItem('Authorization');
 
    if (status === 'Spotlight')
    { 
      if (isMobile && window.ReactNativeWebView) {
        
          const payload = JSON.stringify({ action: 'openNativeSpotlight', token, userPhoto, userName, userType, linkUrl });
          window.ReactNativeWebView.postMessage(payload);  
      
      } else {
        navigate('/' + linkUrl);
      }

     
    }
    else {
      if (shopifyLinkUrl) { 
        if (shopifyLinkUrl.startsWith('www.')) { 
          shopifyLinkUrl = `https://${shopifyLinkUrl}`;
        }
        window.open(shopifyLinkUrl);
      }
   } 
 
  }

    const renderPublishedData = (data) => { 
     
     let filtereddata = data.filter(creation =>  creation.status=='Spotlight' || creation.status=='Gallery' );
 
     return filtereddata.map((creation) => (
      <div className="mycreationTable_box" key={creation.id}>
        
        {Array.isArray(creation.images) &&
          creation.images.map((image) => {
            let path = '';
            if (image.url) {
              const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
              const startIndex = image.url.indexOf(baseUrl);
              if (startIndex !== -1) {
                path = configData.imageUrl + image.url.substring(baseUrl.length) + '/w=250';
              }
            }
            let imagenoUrl = 'https://imagedelivery.net/yJ5Gl_l36RVeosvhpEjUNw/';
            
            return (
              <div className="photos_favorite" key={image.id}>
               
                  <img
                    src={path}
                    alt={image.tag} 
                    id={image.id}
                    onClick={() =>
                      handleSpotlightClick(
                        creation.spotlight.linkUrl,
                        creation.status,
                        creation.spotlight.shopifyLinkUrl
                      )
                   
                    }   style={{ cursor : 'pointer'}}
                  />
             
              </div>
            );
          })}
              <div className='mycreationTable_box_1'>
                <div className='mycreationTable_box_rectangle_TextLike'  >
                  {creation.categoryItems.map((categoryItem) => (
                      categoryItem.category === 'Category' ? ( <div key={categoryItem.id}>
                          {categoryItem.name} 
                        </div>):(<div></div>) 
                  
                  ))}
                  
                </div>
                <button  className='showAll_button'   onClick={() => handleSpotlightClick(creation.spotlight.linkUrl, creation.status,  creation.spotlight.shopifyLinkUrl, )} >
                  <div className='mycreationTable_box_rectangle_appIcon'  >
                      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 21 20" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7929 5.29289C11.1834 4.90237 11.8166 4.90237 12.2071 5.29289L16.2071 9.29289C16.5976 9.68342 16.5976 10.3166 16.2071 10.7071L12.2071 14.7071C11.8166 15.0976 11.1834 15.0976 10.7929 14.7071C10.4024 14.3166 10.4024 13.6834 10.7929 13.2929L13.0858 11L5.5 11C4.94772 11 4.5 10.5523 4.5 10C4.5 9.44772 4.94772 9 5.5 9H13.0858L10.7929 6.70711C10.4024 6.31658 10.4024 5.68342 10.7929 5.29289Z" fill="white"/>
                      </svg>
                  </div>
                </button>
              </div>
              <div className='mycreationTable_box_1'>
                <div className='mycreationTable_box_rectangle_Status'  >
                  {creation.status}
                </div>
              </div>
          </div>
            
      ));
    };

    const renderPendingData = (data) => { 
      
      let filtereddata = data.filter(creation =>  creation.status !='Spotlight' && creation.status!='Gallery' );

      return filtereddata.map((creation) => (
        <div className="mycreationTable_box" key={creation.id}>
          
          {Array.isArray(creation.images) &&
            creation.images.map((image) => {
              let path = '';
              if (image.url) {
                const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                const startIndex = image.url.indexOf(baseUrl);
                if (startIndex !== -1) {
                  path = configData.imageUrl + image.url.substring(baseUrl.length) + '/w=250';
                }
              }
              let imagenoUrl = 'https://imagedelivery.net/yJ5Gl_l36RVeosvhpEjUNw/';
              
              return (
                <div className="photos_favorite" key={image.id}>
                 
                    <img
                      src={path}
                      alt={image.tag} 
                      id={image.id}
                      onClick={() => {
                        if (creation.status === 'Draft') {
                          handleDraftClick(creation.id);
                        } else if (creation.status === 'AwaitingApproval') {
                          handleAwaitingClick(creation.id);
                        } else if (creation.status === 'ChangeRequested') {
                          handleChangeRequestedClick(creation.id);
                        } else if (creation.status === 'Approved') {
                          handleApprovedClick(creation.id);
                        }
                      }}
                    />
                 
                </div>
              );
            })}
                
              <div className='mycreationTable_box_1'>
                  <div className='mycreationTable_box_rectangle_TextLike'>
                     {creation.title}
                  </div>
                  {creation.status === 'Draft' && (
                    <button  className='showAll_button'  onClick={() => handleDraftClick(creation.id)} >
                      <div className='mycreationTable_box_rectangle_appIcon'  >
                          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 21 20" fill="none">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7929 5.29289C11.1834 4.90237 11.8166 4.90237 12.2071 5.29289L16.2071 9.29289C16.5976 9.68342 16.5976 10.3166 16.2071 10.7071L12.2071 14.7071C11.8166 15.0976 11.1834 15.0976 10.7929 14.7071C10.4024 14.3166 10.4024 13.6834 10.7929 13.2929L13.0858 11L5.5 11C4.94772 11 4.5 10.5523 4.5 10C4.5 9.44772 4.94772 9 5.5 9H13.0858L10.7929 6.70711C10.4024 6.31658 10.4024 5.68342 10.7929 5.29289Z" fill="white"/>
                          </svg>
                      </div>
                    </button>
                  )} 
              </div>
              <div className='mycreationTable_box_1'>
                <div className='mycreationTable_box_rectangle_Status'> 
                  {creation.status} 
                    {creation.status === 'AwaitingApproval' && (
                      <button  className='showAll_button'  onClick={() => handleAwaitingClick(creation.id)} >
                        <div className='mycreationTable_box_rectangle_appIcon'  >
                          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                            <path d="M10.5 7V10.5L13.125 13.125M18.375 10.5C18.375 14.8492 14.8492 18.375 10.5 18.375C6.15076 18.375 2.625 14.8492 2.625 10.5C2.625 6.15076 6.15076 2.625 10.5 2.625C14.8492 2.625 18.375 6.15076 18.375 10.5Z" stroke="#B3B3B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                      </button>)
                    }
                    {creation.status === 'ChangeRequested' && (
                        <button  className='showAll_button'  onClick={() => handleChangeRequestedClick(creation.id)} >
                          <div className='mycreationTable_box_rectangle_appIcon'  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                              <path d="M10.5 7V10.5M10.5 14H10.5088M18.375 10.5C18.375 14.8492 14.8492 18.375 10.5 18.375C6.15076 18.375 2.625 14.8492 2.625 10.5C2.625 6.15076 6.15076 2.625 10.5 2.625C14.8492 2.625 18.375 6.15076 18.375 10.5Z" stroke="#B3B3B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </div>
                        </button>)
                    }
                    {creation.status === 'Approved' && (
                        <button  className='showAll_button'  onClick={() => handleApprovedClick(creation.id)} >
                          <div className='mycreationTable_box_rectangle_appIcon'  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                              <path d="M10.5 7V10.5M10.5 14H10.5088M18.375 10.5C18.375 14.8492 14.8492 18.375 10.5 18.375C6.15076 18.375 2.625 14.8492 2.625 10.5C2.625 6.15076 6.15076 2.625 10.5 2.625C14.8492 2.625 18.375 6.15076 18.375 10.5Z" stroke="#B3B3B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </div>
                        </button>)
                    }
                </div>
              </div>
          </div> 
      ));
    };

    return (  
      <div className='table_mycreation'>  
            <Modal
              isOpen={isModalAlertOpen}
              onRequestClose={closeModalAlert}
              contentLabel="Upload Modal"
              className="modal"
              overlayClassName="overlay"
            >
              <Alert 
                isPopupOpen={isModalAlertOpen}
                closePopup={closeModalAlert} 
                title = {isTitle}
                message ={isMessage}
                status = {isStatus}
              />
            </Modal>
        {imagePublishedCount > 0 ? 
          (<div className='detailsTable'>
              <div className='mycreationTable_header'>
                  <div className='mycreationTable_Text'>
                      PUBLISHED ({imagePublishedCount})
                  </div>
                  <button  className='showAll_button'   onClick={handleUpdatePublishedClick} >
                    <div className='mycreationTable_appIcon'>
                        <svg xmlns="http://www.w3.org/2000/svg"   fill="none">
                            <path d="M11.3333 13.3333H10.5V10H9.66667M10.5 6.66667H10.5083M18 10C18 14.1421 14.6421 17.5 10.5 17.5C6.35786 17.5 3 14.1421 3 10C3 5.85786 6.35786 2.5 10.5 2.5C14.6421 2.5 18 5.85786 18 10Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                  </button>
              </div>
              
              <div className='mycreationTable_boxes' >   
                <div className='corner_rounding'>
                  {renderPublishedData(dataAllFilter)}
                </div>
              </div>
            
          </div>) :(<span></span> )
        }
       
      {imagePendingCount > 0 ? 
        (<div className='detailsTable'>
          <div className='mycreationTable_header'>
              <div className='mycreationTable_Text'>
                 PENDING & DRAFTS ({imagePendingCount}) 
              </div>
              <div className='mycreationTable_appIcon'>
                  
              </div>
          </div> 

          <div className='mycreationTable_boxes' >  
            <div className='corner_rounding'>
              {renderPendingData(dataAllFilter)}
            </div>
          </div>
      </div> ) : (<span></span>)}
    </div>
    
  );   
};
  
  
export default MyCreationImages;

  
   