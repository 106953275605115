import React, { useState, useEffect } from 'react';   
import { useNavigate, useLocation, useParams, Link  } from 'react-router-dom';   
import { useData } from '../config/DataContext';  
import '../styles/slider.css';
import Alert from '../components/Alert';
import Modal from 'react-modal'; 
import userSvg from '../images/user.svg';
import ImagePopup from '../components/ImagePopup';
 

export default function SpotLight() {
  return (  
    <MainComponent /> 
  );
};

const MainComponent = () => {    
  const [UserIdQuery, setUserIdQuery] = useState(1);  
  const [Images, setImages] = useState([]);   
  const [Creations, setCreations] = useState([]);  
  const [currentPage, setCurrentPage] = useState(1); 
  const [creationTitle, setcreationTitle] = useState(''); 
  const [creationDescription, setcreationDescription] = useState(''); 
  const [creationCategory1, setcreationCategory1] = useState(''); 
  const [creationCategory2, setcreationCategory2] = useState(''); 
  const [creationCategory3, setcreationCategory3] = useState(''); 
  const [slinkUrl, setslinkUrl] = useState(''); 
  const [firstImage, setfirstImage] = useState([]); 
  const [userName, setuserName] = useState(''); 
  const [userImage, setuserImage] = useState(''); 
  const [userAbout, setuserAbout] = useState(''); 
  const [userlinkUrl, setuserlinkUrl] = useState(''); 
  const [likeCount, setlikeCount] = useState(0); 
  const [likeLimit, setlikeLimit] = useState(0); 
  const [spotlights, setSpotlights] = useState([]);
  
  const [creationCount, setcreationCount] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const location = useLocation();  
  const navigate = useNavigate();
  const configData = useData();
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTitle, setisTitle] = useState('');
  const [isMessage, setisMessage] = useState('');
  const [isStatus, setisStatus] = useState('');
  const { param1, param2 } = useParams();

  const [isModalOpenImage, setisModalOpenImage] = useState(false);
  const [isModalImageurl, setisModalImageurl] = useState(0); 

  let token = localStorage.getItem('Authorization');
  let countCreations=0;
  
  const handleListClick = () => { 
    navigate('/SpotLightList');
  };

  useEffect(() => {
    document.title = "YOUNIQ - Spotlight";
  }, []);

  const handleLinkClick = () => { 
    if (slinkUrl)
      navigate( slinkUrl); 
  };
  
  useEffect(() => {
    const path = location.pathname;    
    const linkUrl = path.substring(1);   
 
    setCreations([]);
    setImages([]);
    if (linkUrl != 'SpotLight') {   
     
        fetchDataLink('Actual', linkUrl);
        fetchData('Actual', '', 2, false);
        setCurrentIndex(0);  
       
    } else { 

      fetchData('Actual', '', 3, true);
      setCurrentIndex(0);  
      
    }  
    if (location)
    {
       //if (Creations.length === 0) setIsNextDisabled(true);
 
    }
   
  }, [location]);

  const processCreations = (data, status, selectstatus) => {

    if (data && Array.isArray(data) && data.length > 0) {
        setIsNextDisabled(false);
       
        setCreations(prevCreations => {  
          
            let trimmedPrevCreations = prevCreations;
 
            const newCreations = data.map(item => ({
                ...item.creation,
                seen: false,
                spotlightId: item.id,
                like: item.like,
                wishList: item.wishList,
                likeCount: item.likeCount,
                likeLimit: item.likeLimit,
                linkUrl : item.linkUrl
            })); 
           
            if (selectstatus) {
                return [...newCreations, ...prevCreations];
            } else {
                const existingIds = new Set(trimmedPrevCreations.map(item => item.id));
                const filteredNewCreations = newCreations.filter(creation => !existingIds.has(creation.id));
                return [...trimmedPrevCreations, ...filteredNewCreations];
            }
        });
 
        const imagesArray = data.flatMap(item =>
            item.creation.images.map(image => ({
                creationId: item.creation.id, 
                id: image.id,
                mediaTypeName: image.mediaTypeName,
                tag: image.tag,
                url : image.url
            }))
        );

        setImages(prevImages => {
          let tprevImages = prevImages;
          if (selectstatus) {
            return   [...imagesArray, ...prevImages] ;
           } else {
            const existingImageKeys = new Set(prevImages.map(image => `${image.id}-${image.creationId}`));
            const newImages = imagesArray.filter(image =>
                !existingImageKeys.has(`${image.id}-${image.creationId}`)
            );
            return [...prevImages, ...newImages];
          }
        });

     
        if (status === true) { 
            updateCreationDetails(data, imagesArray);  
        }  
      
        return data;
    } else {
        if (Creations.length === 0) setIsNextDisabled(true);
        return [];
    }

  }; 

  const updateCreationDetails = (data, imagesArray) => {
    
    const firstCreation = data[0].creation;
    const firstUserId = firstCreation.userId;
    getCreatorInfo(firstUserId, 'Big');
    setslinkUrl(data[0].linkUrl);
    const newUrl = `${window.location.origin}/${data[0].linkUrl}`;
    window.history.pushState({ path: newUrl }, '', newUrl);  
 
    setcreationTitle(firstCreation.title);
    setcreationDescription(firstCreation.description);
  
    const categoryItems = firstCreation.categoryItems;
    setcreationCategory1(categoryItems[0]?.name || '');
    setcreationCategory2(categoryItems[1]?.name || '');
    setcreationCategory3(categoryItems[2]?.name || '');
  
    if (imagesArray.length > 0) {
      const firstImage = imagesArray[0];
      setfirstImage(firstImage);
    }

    const firstCreationCount = data[0];
    setlikeCount(firstCreationCount.likeCount);
    setlikeLimit(firstCreationCount.likeLimit);
  };
   
  const fetchData = async (platform, exceptIds, imagesCount,status) => {
    try {
      const response = await fetch(
        `${configData.apiUrl}Creation/GetNextSpotlight?platform=${platform}&${exceptIds}&count=${imagesCount}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
            'Authorization': token 
           }
        }
      );
      const data = await response.json();
      if (data.length>0) {
          if (status)
          { 
            const newUrl = `${window.location.origin}/${data.data[0].linkUrl}`;
            window.history.pushState({ path: newUrl }, '', newUrl);  
          }
      }
    
      return processCreations(data.data, status, false); 
 
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchDataSelect = async (platform, spotlightId) => {
    try {
      const response = await fetch(
        `${configData.apiUrl}Creation/GetSpotlight?spotlightId=${spotlightId}&platform=${platform}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
            'Authorization': token 
           }
        }
      );
      
      const data = await response.json(); 
      const dataArray = [data]; 
     
      return processCreations(dataArray, true, true);
      
    } catch (error) {
      console.log(error);
    }
  };
  
  const fetchDataLink = async (platform, linkUrl) => {
    try {
      const response = await fetch(
        `${configData.apiUrl}Creation/GetSpotlightByLink?linkUrl=${linkUrl}&platform=${platform}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json',
            'Authorization': token 
           }
        }
      );
      
      const data = await response.json(); 
      const dataArray = [data]; 
      // console.log(JSON.stringify(dataArray))
      if (dataArray.length>0) {
        const newUrl = `${window.location.origin}/${linkUrl}`;
        window.history.pushState({ path: newUrl }, '', newUrl);  
      }
        
      return processCreations(dataArray, true, true);
      
    } catch (error) {
      console.log(error);
    }
  };
  

  const spotlightSelect = (platform, spotlightId, status, shopifyLinkUrl,linkUrl) => { 
    if (status === 'Spotlight')
    {
      setCreations([]);  
      setImages([]);
      fetchDataSelect(platform, spotlightId); 
      const newUrl = `${window.location.origin}/${linkUrl}`;
      window.history.pushState({ path: newUrl }, '', newUrl); 
        
      const exceptIdsArray = Creations.map(c => c.id);                 
      const exceptIdsQuery = exceptIdsArray.map(id => `exceptIds=${id}`).join('&');
      fetchData(platform, exceptIdsQuery, 2, false);
      setCurrentIndex(0);
    } else {
      if (shopifyLinkUrl) {
        
        if (shopifyLinkUrl.startsWith('www.')) { 
          shopifyLinkUrl = `https://${shopifyLinkUrl}`;
        }
        window.location.href =  shopifyLinkUrl;
      }
    } 
  };
   
  const getCreatorInfo = async (userId,platform) => {
    try { 
      const response = await fetch(
        `${configData.apiUrl}User/GetUserProfile?userId=${userId}&platform=${platform}&includeSpotlights=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
        }
      );
      const data = await response.json();      
      setuserName(data.name);
      setuserAbout(data.about);
      if (data.linkUrl)
      {
        let url= data.linkUrl;
        if (data.linkUrl.startsWith('www.')) { 
          url = `https://${data.linkUrl}`;
        }
        setuserlinkUrl(url);
      }
      if (data.picture)
      {
        setuserImage(data.picture);   
      } else setuserImage('');
      setSpotlights(data.spotlights.spotlights.map((item, index) => ({
        ...item,
        creationId: item.creation.id,  
        spotlightId: item.id
      })));
      setcreationCount(data.spotlights.spotlightCount);
    } catch (error) {
      console.log(error);
    }
  };

  const seenLikeAction = async (id,status,pramprocess) => { 
    let requestBody = {
        process: pramprocess,
        userId: UserIdQuery,  
        action: status,
        forType: "Spotlight",
        forId: id, 
        note: ""
    };

    if (pramprocess === 'Clear') 
    {
        requestBody = {
          process: pramprocess,
          userId: UserIdQuery,  
          action: 'Seen',
          forType: "Spotlight" 
      }; 
    }
    
    try {
        const response = await fetch(`${configData.apiUrl}Common/TakeAction`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token 
            },
            body: JSON.stringify(requestBody),
        });

        if (response) { 
            const result = await response.json();
            console.log(requestBody);
      
            if (pramprocess === 'Clear') 
            {
              setCreations([]); 
              fetchData('Actual', [0], 3, true);   
              navigate("/SpotLight");
            }
        } else {
            console.error('Error in response:', response.statusText);
        }
    } catch (error) {
        console.error('Favorite states update error:', error);
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0); 

  const handleNextPage = (pagesCount) => {  
    if (currentPage < pagesCount) {
        setCurrentPage(prevPage => prevPage + 1);
    } 
  };

  const handlePreviousPage = () => {
      if (currentPage > 1) {
          setCurrentPage(prevPage => prevPage - 1);
      }
  };
  
  const handleNext = (status) => {
    setCurrentIndex((prevIndex) => {
      const spotlightId = JSON.parse(localStorage.getItem('activeCreation'));
      const activeCreation = Creations.find(creation => creation.spotlightId === spotlightId);
      const activespotLightId = spotlightId ? spotlightId : null;
      const activeuserId = activeCreation ? activeCreation.userId : null;
     
      seenLikeAction(activespotLightId, 'Seen', 'Add');
  

      let newUnseenCreations = Creations.filter(creation => creation.seen === false);
      let seencount = newUnseenCreations.length -1;
    
      if (status!==1) {
        const updatedCreations = Creations.map(item => ({
          ...item,
          seen: item.id === activeCreation.id ? true : item.seen
        }));
    
        setCreations(updatedCreations);
        newUnseenCreations = updatedCreations.filter(creation => creation.seen === false);
        seencount = newUnseenCreations.length;
      }  
  
      const exceptIdsArray = Creations
      .slice(0, -1)  
      .map(c => c.id);         

      const exceptIdsQuery = exceptIdsArray.map(id => `exceptIds=${id}`).join('&');
     
      let finisharray =false;
      if (seencount === 1) { 
          fetchData('Actual', exceptIdsQuery, 1, false).then(newData => {
            if (!newData || newData.length === 0) {
              finisharray =true; 
            } 
          });
      } 
 
       if (newUnseenCreations.length === 0 || finisharray === true) { 
          setIsNextDisabled(true);   
          return prevIndex;
        }
    

      const nextIndex = (prevIndex + 1) % Creations.length;  
      const nextCreation = Creations[nextIndex];
  
      setcreationTitle(nextCreation.title);
      setcreationDescription(nextCreation.description);
      setslinkUrl(nextCreation.linkUrl);
 
      const nextUserId = nextCreation ? nextCreation.userId : null;
     

      getCreatorInfo(nextUserId,'Big');
       
      let newUrl = `${window.location.origin}/SpotLight`;
      if (nextCreation.linkUrl)
      {
          newUrl = `${window.location.origin}/${nextCreation.linkUrl}`;   
      }  

      window.history.pushState({ path: newUrl }, '', newUrl);
      const categoryItems = nextCreation.categoryItems;
      setcreationCategory1(categoryItems[0]?.name || '');
      setcreationCategory2(categoryItems[1]?.name || '');
      setcreationCategory3(categoryItems[2]?.name || '');
  
      const filteredImages = Images.filter(image => image.creationId === nextCreation.id);
      setfirstImage(filteredImages[0] || null);
 
      const count = nextCreation.likeCount;
      setlikeCount(count);

      const limit = nextCreation.likeLimit;
      setlikeLimit(limit);
 
      localStorage.setItem('activeCreation', JSON.stringify(nextCreation.spotlightId));
      setCurrentPage(1);
      setIsPrevDisabled(false)
      return nextIndex;
    });
  };
 
  const handleLike = (status) => {
    const spotlightId = JSON.parse(localStorage.getItem('activeCreation'));
    const activeCreation = Creations.find(creation => creation.spotlightId === spotlightId);
    const activespotLightId = spotlightId ? spotlightId : null; 

    let actionStatus= activeCreation ? activeCreation.like : false;
    let likeStatus ='';
 
    if (activeCreation) {
 
      if (status==='Like')
      { 
        if (actionStatus===true) likeStatus="Remove";
        else likeStatus="Add";
        seenLikeAction(activespotLightId, 'Like', likeStatus);
      }
      else if (status==='Wish')
      { 
        actionStatus= activeCreation ? activeCreation.wishList : false;
        if (actionStatus===true) likeStatus="Remove";
        else likeStatus="Add";
        
        seenLikeAction(activespotLightId, 'wishList', likeStatus);
      }

      setCreations(prevCreations => {
        const updatedCreations = prevCreations.map(item => {
          if (item.id === activeCreation.id) {
            return {
              ...item,
              like: status === 'Like' ? (likeStatus === 'Add' ? true : false) : item.like,
              wishList: status === 'Wish' ? (likeStatus === 'Add' ? true : false) : item.wishList,
              seen: true,
              likeCount: status === 'Like' 
                ? (likeStatus === 'Add' ? item.likeCount + 1 : item.likeCount - 1)
                : item.likeCount
            };
          }
          return item;
        });
        return updatedCreations;
      }); 
      
      if (status ==='Like')
      {         
          setIsLiked(true);
 
          setTimeout(() => {
            if (status === 'Like') { 
              handleNext(1);
            }
            setIsLiked(false);   
          }, 50);
      }
   
    }
  }; 

  const handlePrev = () => {
    if (Creations.length === 0) return; 

    setCurrentIndex((prevIndex) => {
      const spotlightId = JSON.parse(localStorage.getItem('activeCreation'));
      const activeCreation = Creations.find(creation => creation.spotlightId === spotlightId);
      const activeuserId = activeCreation ? activeCreation.userId : null;
    
     
      const prevIndexAdjusted = (prevIndex - 1 + Creations.length) % Creations.length;
      const prevCreation = Creations[prevIndexAdjusted];

      const prevuserId = prevCreation ? prevCreation.userId : null;
   
      getCreatorInfo(prevuserId, 'Big');
  
      let newUrl = `${window.location.origin}/SpotLight`;
      if (prevCreation.linkUrl)
      {
          newUrl = `${window.location.origin}/${prevCreation.linkUrl}`;   
      }  
      window.history.pushState({ path: newUrl }, '', newUrl);

      setcreationTitle(prevCreation.title);
      setcreationDescription(prevCreation.description);

      const categoryItems = prevCreation.categoryItems;
      setcreationCategory1(categoryItems[0]?.name || '');
      setcreationCategory2(categoryItems[1]?.name || '');
      setcreationCategory3(categoryItems[2]?.name || '');

      const filteredImages = Images.filter(image => image.creationId === prevCreation.id);
      setfirstImage(filteredImages[0] || null);

      const count = prevCreation.likeCount;
      setlikeCount(count);

      const limit = prevCreation.likeLimit;
      setlikeLimit(limit);

      setCurrentPage(1);

      localStorage.setItem('activeCreation', JSON.stringify(prevCreation.spotlightId));

      setIsPrevDisabled(prevIndexAdjusted === 0);

      return prevIndexAdjusted;
    });
  };

 
  const openModal = () => {
    setIsModalOpen(true);
    setTimeout(() => {
        closeModal();
    }, 2000);
  };
  
  const closeModal = () => {
    setIsModalOpen(false); 
  };

  const copyToClipboard = () => {
    const linkToCopy = window.location.href; 
      navigator.clipboard.writeText(linkToCopy).then(() => {
        setisTitle('Copy Link');
        setisMessage('Link copied to clipboard!');
        setisStatus('');
        openModal();
      }).catch(err => {
          console.error('Failed to copy the link: ', err);
      });
  };
 
  const handlePrev1 = () => {
    if (Creations.length === 0) return; 
    setCurrentIndex((prevIndex) => {
      const spotlightId = JSON.parse(localStorage.getItem('activeCreation'));
      const activeCreation = Creations.find(creation => creation.spotlightId === spotlightId);
      const activeuserId = activeCreation ? activeCreation.userId : null;
   
      getCreatorInfo(activeuserId, 'Big');
   
      const prevIndexAdjusted = (prevIndex - 1 + Creations.length) % Creations.length;
      const prevCreation = Creations[prevIndexAdjusted];
 
      setcreationTitle(prevCreation.title);
      setcreationDescription(prevCreation.description);
  
      const categoryItems = prevCreation.categoryItems;
      setcreationCategory1(categoryItems[0]?.name || '');
      setcreationCategory2(categoryItems[1]?.name || '');
      setcreationCategory3(categoryItems[2]?.name || '');
  
      const filteredImages = Images.filter(image => image.creationId === prevCreation.id);
      setfirstImage(filteredImages[0] || null);

      const count = prevCreation.likeCount;
      setlikeCount(count);

      const limit = prevCreation.likeLimit;
      setlikeLimit(limit);

      setCurrentPage(1);
       
      localStorage.setItem('activeCreation', JSON.stringify(prevCreation.spotlightId));
  
      return prevIndexAdjusted;
    });
  };

  const openModalImage = (url) => {
    
    let path = '';
    if (url) {
      const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
      const startIndex = url.indexOf(baseUrl);
      if (startIndex !== -1) {
        path = configData.imageUrl + url.substring(baseUrl.length)  ;
      }
    }
    setisModalImageurl(path);
  
    setisModalOpenImage(true); 
  };

  const closeModalImage = (status,imageId) => {
    setisModalOpenImage(false);
    
  };

  
  const renderimageData = () => { 
    if (Creations.length === 0) {
     
      return (<div className='nodata'></div>);  
    }
    
    if (Creations.length > 0) {
      localStorage.setItem('isCreations', 'true');  
    
      const hasImages = Creations.some(creation => 
        Images.some(image => image.creationId === creation.id)
      );
  
      if (!hasImages) {
        return (<div className='nodata'></div>);  
      } 
  
      return (
       <div className='sliderBox'> 
        <div className='slider-container'>
          <div className='slider-wrapper'>
            {Creations.map((creation, index) => {
              const isActive = index === currentIndex;
              const isPrev = index === currentIndex - 1;
              const isNext = index === currentIndex + 1;
              if (isActive) {
                localStorage.setItem('activeCreation', JSON.stringify(creation.spotlightId));
              }
  
              let crImages = Images.filter(image => image.creationId === creation.id);
              let cImages = isActive
                ? crImages.slice(currentPage - 1, currentPage)
                : crImages.slice(0, 1); 
                
              return (
                <>
                <div className='imageSlider'>
                  <div
                    key={index}
                    className={`slider-image-wrapper ${isActive ? 'active' : isPrev ? 'prev' : isNext ? 'next' : ''}`}
                  ><div  className="rectangleImages">
                    {
                    cImages.map((image) => {
                      let path = '';
                      if (image.url) {
                        const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                        const startIndex = image.url.indexOf(baseUrl);
                        if (startIndex !== -1) {
                          path = configData.imageUrl + image.url.substring(baseUrl.length) + '/w=500';
                        }
                      }
                       
                      return (
                       <>
                      <img
                          key={image.id}
                          src={path}
                          alt={`Image ${image.id}`}
                          style={{
                            width: 'auto',
                            height: '100%',
                            objectFit: 'contain'
                          }}
                          onClick={isActive ? () => openModalImage(image.url) : null}
                        />

                        <Modal
                        isOpen={isModalOpenImage}
                        onRequestClose={closeModalImage}
                        contentLabel="Upload Modal"
                        className="modal"
                        overlayClassName="overlay"
                      >
                        <ImagePopup
                          isPopupOpen={isModalOpenImage}
                          closePopup={closeModalImage}
                          CollectionType={'CustomImages'}
                          Status={'Details'}
                          imageUrl = {isModalImageurl}
                        />
                      </Modal> 
                      </>
                      );
                    })
                    
                    }
                    </div>
                 
                 
                </div></div>
                   {isActive && (
                    
                      <div className="imagePageControl">
                        {renderPagination(crImages.length)}
                      </div>
                    
                  )}
                  
                </>
              );
            })}
          </div>
        </div>
      </div>
      );
    } else {
      return <p>All creations have been seen!</p>;
    }
  };
   

  const renderPagination = (pagesCount) => { 
    const pageIcons = [...Array(pagesCount).keys()].map(i => (
      <button 
        key={i}
        className={`page-icon ${currentPage === i + 1 ? 'active' : ''}`}
        onClick={() => setCurrentPage(i + 1)}
        style={{ background: 'transparent', border: 'none' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="8" height="8">
          <circle cx="8" cy="8" r="10" fill={currentPage === i + 1 ? '#fff' : '#5E5E5E'} />
        </svg>
      </button>
    ));

    return (
      <> 
          <button onClick={handlePreviousPage} disabled={currentPage === 1} className='showAll_button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 21" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.3424 5.55754C13.7525 5.96759 13.7525 6.63241 13.3424 7.04246L9.8849 10.5L13.3424 13.9575C13.7525 14.3676 13.7525 15.0324 13.3424 15.4425C12.9324 15.8525 12.2676 15.8525 11.8575 15.4425L7.65751 11.2425C7.24746 10.8324 7.24746 10.1676 7.65751 9.75754L11.8575 5.55754C12.2676 5.14749 12.9324 5.14749 13.3424 5.55754Z" fill="white"/>
                </svg>
            </button>
            {pageIcons}
            <button onClick={() => handleNextPage(pagesCount)} disabled={currentPage === pagesCount} className='showAll_button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21 21" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.65751 15.4425C7.24746 15.0324 7.24746 14.3676 7.65751 13.9575L11.1151 10.5L7.65751 7.04246C7.24746 6.63241 7.24746 5.96759 7.65751 5.55754C8.06756 5.14749 8.73239 5.14749 9.14244 5.55754L13.3424 9.75754C13.7525 10.1676 13.7525 10.8324 13.3424 11.2425L9.14244 15.4425C8.73239 15.8525 8.06756 15.8525 7.65751 15.4425Z" fill="white"/>
                </svg>
            </button>
      </> 
    );
  };

  return ( 
    <div className='content'>  
        
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Upload Modal"
          className="modal"
          overlayClassName="overlay"
        >
          <Alert 
            isPopupOpen={isModalOpen}
            closePopup={closeModal} 
            title={isTitle}
            message={isMessage}
            status={isStatus} 
          />
        </Modal>

      <div className='contentSpotLight' > 
        <div className={`sidebar_Left ${isNextDisabled ? 'hidden' : ''}`}>
          <div className='sidebar_LeftSub'> 
               <div className='creatorImage' >
  
                {userImage ? (
                      (() => {
                        let path = '';
                        if (userImage.url) {
                          const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                          const startIndex = userImage.url.indexOf(baseUrl);
                          if (startIndex !== -1) {
                            path = configData.imageUrl + userImage.url.substring(baseUrl.length) + '/w=250';
                          }
                        }

                        return (
                          <>
                          {path ? (<img
                            src={path}
                         
                          />) : (<img src={userSvg}   className='creatorImage' />) }
                          </>
                        );
                      })( <img src={userSvg}   className='creatorImage' />)
                    ) :  <img src={userSvg}   className='creatorImage' />}
               </div>
               <div className='creatorTextTop'>
                  <div className='creatorName' >
                    <a href={userlinkUrl}>  {userName} </a>
                  </div>
                   
               </div>
               <div className='creatorTotalCreation'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <path d="M4.66667 2.5V5.83333M3 4.16667H6.33333M5.5 14.1667V17.5M3.83333 15.8333H7.16667M11.3333 2.5L13.2381 8.21429L18 10L13.2381 11.7857L11.3333 17.5L9.42857 11.7857L4.66667 10L9.42857 8.21429L11.3333 2.5Z" stroke="white" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <div  className='creatorTotalCreationText' >
                    {creationCount} creations 
                  </div>
         
               </div>
               <div className="spotlightContainer">
                    {spotlights.slice(-5).map((spotlight, index) => (
                      <div key={spotlight.id} className="spotlightItem">
                     {spotlight.creation.images && 
                        (spotlight.creation.images.map((image, i) => {
                          let path = '';
                          if (image.url) {
                            const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                            const startIndex = image.url.indexOf(baseUrl);
                            if (startIndex !== -1) {
                              path = configData.imageUrl + image.url.substring(baseUrl.length) + '/w=45';
                            }
                          }
                        return (

                         <img
                         key={i}
                         src={path}
                         alt={spotlight.creation.title}
                         className="spotlightImage"
                         onClick={() => spotlightSelect('Actual', spotlight.id, spotlight.creation.status, spotlight.shopifyLinkUrl, spotlight.linkUrl)}  
                       /> );
                        }
                      ))}
                      </div>
                    ))}
                </div>
              <div className='creatorAbout'>
                  {userAbout}
              </div>
          </div>
        </div>
        <div className='middleBlock'> 
         
         {renderimageData()}  
        
          <div className={`bottomRectangle ${isNextDisabled ? 'hidden' : ''}`}> 
            <div className="frameAction">
            <button onClick={handlePrev} className="showAll_button" disabled={isPrevDisabled}>
                  <div className="iconBack"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none" className='iconBack_App'>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24477 0.451569C7.7134 0.920199 7.7134 1.68 7.24477 2.14863L4.4933 4.9001H11.1962C15.8354 4.9001 19.5962 8.66091 19.5962 13.3001V15.7001C19.5962 16.3628 19.059 16.9001 18.3962 16.9001C17.7335 16.9001 17.1962 16.3628 17.1962 15.7001V13.3001C17.1962 9.98639 14.5099 7.3001 11.1962 7.3001H4.4933L7.24477 10.0516C7.7134 10.5202 7.7134 11.28 7.24477 11.7486C6.77614 12.2173 6.01634 12.2173 5.54771 11.7486L0.747712 6.94863C0.279083 6.48 0.279083 5.7202 0.747712 5.25157L5.54771 0.451569C6.01634 -0.0170597 6.77614 -0.0170597 7.24477 0.451569Z" fill="#D9D9D9" fill-opacity="0.6"/>
                    </svg>
                    Back
                  </div> 
                </button>
                <button onClick={() => handleLike('Like')}  className="showAll_button">  
                    <div className="iconVote"> 
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="23"
                        viewBox="0 0 27 23"
                        fill="none" 
                        style={{
                          fill: isLiked ? "#FEF6F6" : (Creations[currentIndex]?.like === true ? "#FEF6F6" : "none"),  
                          fillOpacity: isLiked ? "1" : (Creations[currentIndex]?.like ? "1" : "0.5"),
                        }}   className='iconVote_App' >
                        <path
                          d="M13.1426 22.0962C13.2364 22.19 13.3636 22.2427 13.4962 22.2427C13.6288 22.2427 13.756 22.19 13.8497 22.0962L24.0923 11.8535C26.6307 9.31511 26.6307 5.19954 24.0923 2.66113C21.5539 0.122722 17.4383 0.122722 14.8999 2.66113L14.8999 2.66115L13.4962 4.06499L12.0923 2.66113C9.55388 0.122723 5.43831 0.122722 2.8999 2.66113C0.361492 5.19954 0.361492 9.31511 2.8999 11.8535L13.1426 22.0962Z"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Like
                    </div> 
                </button>
           
                <button onClick={() => handleNext(0)} className="showAll_button"  > 
                  <div className="iconNext">  
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7516 0.451569C12.2829 0.920199 12.2829 1.68 12.7516 2.14863L15.503 4.9001H8.8001C4.1609 4.9001 0.400097 8.66091 0.400097 13.3001V15.7001C0.400097 16.3628 0.937355 16.9001 1.6001 16.9001C2.26284 16.9001 2.8001 16.3628 2.8001 15.7001V13.3001C2.8001 9.98639 5.48639 7.3001 8.8001 7.3001H15.503L12.7516 10.0516C12.2829 10.5202 12.2829 11.28 12.7516 11.7486C13.2202 12.2173 13.98 12.2173 14.4486 11.7486L19.2486 6.94863C19.7173 6.48 19.7173 5.7202 19.2486 5.25157L14.4486 0.451569C13.98 -0.0170597 13.2202 -0.0170597 12.7516 0.451569Z" fill="#D9D9D9" fill-opacity="0.6"/>
                      </svg>
                      Next
                  </div> 
                </button>
            </div> 
            <button onClick={() => handleLike('Wish')} className="showAll_button"> 
                    <div className="wishlistAction">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="12"
                        viewBox="0 0 10 12"
                        fill="none"
                        className="wishlistAction_App"
                        style={{
                          fill: Creations[currentIndex]?.wishList ? "#FEF6F6" : "none",  
                          fillOpacity: Creations[currentIndex]?.wishList ? "1" : "0.5",   
                        }}
                      >
                        <path
                          d="M9 2.00003C9 1.36885 8.48833 0.857178 7.85714 0.857178H2.14286C1.51167 0.857178 1 1.36885 1 2.00003V11.1429L5 9.14289L9 11.1429V2.00003Z"
                          stroke="#D9D9D9"
                          strokeOpacity="0.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      Wishlist
                    </div>
          </button>

          </div> 

        
          {isNextDisabled   && (  <div className='lastRecordMessageBlock'
           >
              <div className="lastRecordMessage_App">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <path d="M12 16L14.6667 18.6667L20 13.3333M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z" stroke="#14AE5C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              </div>
            <div className='lastRecordMessage'>
                YOU’RE CAUGHT UP FOR TODAY
            </div>

            <div className="lastRecordActionBlock">
                <button onClick={handlePrev} className="showAll_button"> 
                  <div className="iconBack"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none" className='iconBack_App'>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.24477 0.451569C7.7134 0.920199 7.7134 1.68 7.24477 2.14863L4.4933 4.9001H11.1962C15.8354 4.9001 19.5962 8.66091 19.5962 13.3001V15.7001C19.5962 16.3628 19.059 16.9001 18.3962 16.9001C17.7335 16.9001 17.1962 16.3628 17.1962 15.7001V13.3001C17.1962 9.98639 14.5099 7.3001 11.1962 7.3001H4.4933L7.24477 10.0516C7.7134 10.5202 7.7134 11.28 7.24477 11.7486C6.77614 12.2173 6.01634 12.2173 5.54771 11.7486L0.747712 6.94863C0.279083 6.48 0.279083 5.7202 0.747712 5.25157L5.54771 0.451569C6.01634 -0.0170597 6.77614 -0.0170597 7.24477 0.451569Z" fill="#D9D9D9" fill-opacity="0.6"/>
                    </svg>
                    Back
                  </div> 
                </button>
                <button onClick={() => seenLikeAction(0, '', 'Clear')} className="showAll_button" >  
                    <div className="iconSuffle"> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M4 4V9H4.58152M19.9381 11C19.446 7.05369 16.0796 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9M4.58152 9H9M20 20V15H19.4185M19.4185 15C18.2317 17.9318 15.3574 20 12 20C7.92038 20 4.55399 16.9463 4.06189 13M19.4185 15H15" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      Shuffle                    
                    </div> 
                </button>
           
                <button  className="showAll_button" onClick={handleListClick}  > 
                  <div className="iconSuffle">  
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M19 11H5M19 11C20.1046 11 21 11.8954 21 13V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V13C3 11.8954 3.89543 11 5 11M19 11V9C19 7.89543 18.1046 7 17 7M5 11V9C5 7.89543 5.89543 7 7 7M7 7V5C7 3.89543 7.89543 3 9 3H15C16.1046 3 17 3.89543 17 5V7M7 7H17" stroke="#D9D9D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    See all
                  </div> 
                </button>
            </div> 
           
          </div>)}

          <div className={`bottomBlock ${isNextDisabled ? 'hidden' : ''}`}>
          <button onClick={handleListClick} className="showAll_button">  
             <div className='seeAllCreation'> 
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                  <g clip-path="url(#clip0_1442_1477)">
                    <path d="M2.40939 15H9.87386C11.0297 15 11.7077 14.3285 11.7077 12.9983V5.67588C11.7077 4.34569 11.0232 3.67416 9.68015 3.67416H2.40939C1.05984 3.67416 0.381836 4.33924 0.381836 5.67588V12.9983C0.381836 14.3285 1.05984 15 2.40939 15ZM2.4223 13.9604C1.77658 13.9604 1.42144 13.6117 1.42144 12.9402V5.72753C1.42144 5.06244 1.77658 4.71375 2.4223 4.71375H9.66078C10.3 4.71375 10.6681 5.06244 10.6681 5.72753V12.9402C10.6681 13.6117 10.3 13.9604 9.85448 13.9604H2.4223ZM1.7895 2.63455H10.2936C10.1967 2.04695 9.91909 1.72409 9.27334 1.72409H2.80973C2.16401 1.72409 1.88636 2.04695 1.7895 2.63455ZM2.91305 0.845913H9.17651C9.13776 0.297055 8.82134 0.0129395 8.2273 0.0129395H3.85579C3.26173 0.0129395 2.95179 0.297055 2.91305 0.845913Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1442_1477">
                      <rect width="11.3259" height="15" fill="white" transform="translate(0.381836)"/>
                    </clipPath>
                  </defs>
                </svg>
                See All Designs
              </div>
            </button>
              <div className='progressBar'>
                <div className='progressBarSub'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                        <path 
                          fillRule="evenodd" 
                          clipRule="evenodd" 
                          d="M3.83025 5.4301C5.47045 3.7899 8.12974 3.7899 9.76995 5.4301L11.0001 6.66025L12.2302 5.4301C13.8705 3.7899 16.5297 3.7899 18.1699 5.4301C19.8101 7.0703 19.8101 9.7296 18.1699 11.3698L11.0001 18.5396L3.83025 11.3698C2.19005 9.7296 2.19005 7.0703 3.83025 5.4301Z" 
                          fill={likeCount > likeLimit ? "#14AE5C" : "white"} 
                          fillOpacity="0.6" 
                        />
                     </svg>
                     <div className='progressText'>
                      {likeCount}/{likeLimit}
                    </div>
                    <a onClick={() => copyToClipboard()} style={{ cursor: 'pointer' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                      <path d="M16.25 14.07C15.585 14.07 14.99 14.3325 14.535 14.7438L8.29625 11.1125C8.34 10.9113 8.375 10.71 8.375 10.5C8.375 10.29 8.34 10.0887 8.29625 9.8875L14.465 6.29125C14.9375 6.72875 15.5588 7 16.25 7C17.7025 7 18.875 5.8275 18.875 4.375C18.875 2.9225 17.7025 1.75 16.25 1.75C14.7975 1.75 13.625 2.9225 13.625 4.375C13.625 4.585 13.66 4.78625 13.7038 4.9875L7.535 8.58375C7.0625 8.14625 6.44125 7.875 5.75 7.875C4.2975 7.875 3.125 9.0475 3.125 10.5C3.125 11.9525 4.2975 13.125 5.75 13.125C6.44125 13.125 7.0625 12.8537 7.535 12.4162L13.765 16.0563C13.7212 16.24 13.695 16.4325 13.695 16.625C13.695 18.0338 14.8413 19.18 16.25 19.18C17.6588 19.18 18.805 18.0338 18.805 16.625C18.805 15.2163 17.6588 14.07 16.25 14.07Z" fill="#D9D9D9" fill-opacity="0.6"/>
                    </svg>
                    </a>
                  </div>
                <div
                  className="progressLine"
                  style={{
                    width: `${likeCount > likeLimit ? 100 : (likeCount / likeLimit) * 100}%`,
                    backgroundColor: likeCount > likeLimit  ? '#14AE5C' : '#D9D9D9',
                  }}
                ></div>
              </div>
          </div>
          <div className={`middleBlockSVG ${isNextDisabled ? 'hidden' : ''}`}> 
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="402" viewBox="0 0 839 402" fill="none">
              <g filter="url(#filter0_f_1442_1444)">
                <path d="M261.5 165H547.5L673.5 380H165.5L261.5 165Z" fill="url(#paint0_radial_1442_1444)" fill-opacity="0.58"/>
              </g>
              <defs>
                <filter id="filter0_f_1442_1444" x="0.5" y="0" width="100%" height="100%" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                  <feGaussianBlur stdDeviation="82.5" result="effect1_foregroundBlur_1442_1444"/>
                </filter>
                <radialGradient id="paint0_radial_1442_1444" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(419.5 272.5) rotate(90) scale(107.5 254)">
                  <stop stop-color="#E9E9B3"/>
                  <stop offset="1" stop-color="#CC6C0D"/>
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className={`sidebar_Right ${isNextDisabled ? 'hidden' : ''}`}>
          <div className='sidebar_RightSub'>   
                  <div className='creationThumb' >
                  {firstImage ? (
                      (() => {
                        let path = '';
                        if (firstImage.url) {
                          const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                          const startIndex = firstImage.url.indexOf(baseUrl);
                          if (startIndex !== -1) {
                            path = configData.imageUrl + firstImage.url.substring(baseUrl.length) + '/w=125';
                          }
                        }

                        return (
                          <img
                            key={firstImage.id}
                            src={path}
                            alt={`Image ${firstImage.id}`}
                          />
                        );
                      })()
                    ) : null}

                  </div>
                  <div className='creatorTextTop_Big'>
                      <div className='creatorName' >
                          {creationTitle}  
                      </div>
                    
                  </div>
                  <div className='categoryFrame'> 
                      <div  className='categorySub' >
                          {creationCategory1}
                      </div>
                      <div  className='categorySub' >
                          {creationCategory2}
                      </div>
                      <div  className='categorySub' >
                          {creationCategory3}
                      </div>

                  </div>
                  <div className='creatorAbout'>
                       {creationDescription}
                
                  </div>
              </div> 
        </div>
     
       

      </div>
    </div>
  )
}
