import React, { useState, useEffect } from 'react'; 
import { useNavigate, useLocation, useHistory} from 'react-router-dom'; 
 
import { useData } from '../config/DataContext';  

export default function Login() {
  return <MainComponent />;
}

const MainComponent = () => {   
  const [Email, setEmail] = useState(''); 
  const [isEmailValid, setisEmailValid] = useState(true);
  const [Password, setPassword] = useState('');  
  const [isPasswordValid, setisPasswordValid] = useState(true);
  const [isMessage, setisisMessage] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); 
  const location = useLocation();
  const configData = useData();  
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    document.title = "YOUNIQ - Login";
  }, []);


  const handleChange = (status, event) => { 
    if (status === 'Email') {
        setEmail(event.target.value);
        setisEmailValid(true);
    } else if (status === 'Password') {
        setPassword(event.target.value);
        setisPasswordValid(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleLoginClick();
    }
  };
  
  const handleLinkClick = (status) => { 
    let path;
    if (status === 'pass') { 
      path = 'https://getyouniq.com/account/login#recover';
    } else if (status === 'create') {
      path = 'https://getyouniq.com/account/register';
    } 
    window.open(path);   
  };

  const handleLoginClick = async () => {  
   
    try { 
      let requestBody = {
        email: Email,
        password: Password   
      };
       
      const response = await fetch(`${configData.apiUrl}User/Authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',  
        },
        body: JSON.stringify(requestBody),
      });
       
      if (!response.ok) {
        setisisMessage("Unidentified customer - Username or password is incorrect");

        console.error('Error in response:', response.statusText);
        return;
      }
      const result = await response.json();
  
      let token = result.type + ' ' + result.token;
      localStorage.setItem('Authorization', token );
      localStorage.setItem('expiresAt', result.expiresAt);
      localStorage.setItem('userType', result.userType);
     
      getCreatorInfo(result.type + ' ' + result.token, 'Big');

      let userName = localStorage.getItem('userName'); 
      let userPhoto = localStorage.getItem('userPhoto');
      let userType =  localStorage.getItem('userType');

      const redirectPath = localStorage.getItem('redirectPath') || '/SpotLight';
    
      if (isMobile && window.ReactNativeWebView) {
        if (redirectPath === "/SpotLight") {
          const payload = JSON.stringify({ action: 'openNativeSpotlight', token, userPhoto, userName, userType });
          window.ReactNativeWebView.postMessage(payload);  
        } else {
          localStorage.removeItem('redirectPath'); 
          navigate(redirectPath, { replace: true });
        }
      } else {
        localStorage.removeItem('redirectPath'); 
        navigate(redirectPath, { replace: true });
      }
 
      
    } catch (error) { 
    //  setisisMessage(error);
     alert(error)
      console.error(error);
    }
  };
  
  const getCreatorInfo = async (token, platform) => {
    try { 
     
      const response = await fetch(
        `${configData.apiUrl}User/GetMyProfile?platform=${platform}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
        }
      );
      const data = await response.json();      
      localStorage.setItem('userName', data.name || 'User');  
      
      if (data.picture) {
          localStorage.setItem('userPhoto', JSON.stringify(data.picture));
         
      } else { 
          localStorage.removeItem('userPhoto');   
      }

     // console.log(JSON.stringify(data.picture));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='content'>
      <div className='MyStudioContent'> 
        <div className='headerTextBlock'>
          <div className='headerTitle'> 
            A COMMUNITY OF FASHION GEEKS WITH BOLD CREATIVITY 
          </div> 
          <div className='headerText'> 
            Log-in to the tech-platform to have access to become a creator, 
            vote creations and engage with the community 
          </div> 
        </div> 
        <div className='loginTable'> 
          <div className='profileInformationTableLeft'>
            <div>
              <div className='profileInformationTableTitle'> 
                E-MAIL
              </div> 
              <div className='logintextBlock'>  
                <div className='logintext'> 
                  <input type='text' placeholder='E-mail' value={Email} 
                   onChange={(e) => handleChange('Email', e)}
                   onKeyDown={handleKeyDown}   
                  />    
                </div> 
                <div className='alertmessage'>{!isEmailValid && <div className='error-message'> !!! </div>}
                </div>
              </div>
            </div>
            <div>
              <div className='profileInformationTableTitle'> 
                PASSWORD
              </div> 
              <div className='logintextBlock'>  
                <div className='logintext'> 
                  <input  placeholder='Password' value={Password} type='password' 
                   onChange={(e) => handleChange('Password', e)}
                   onKeyDown={handleKeyDown} 
                  />    
                </div> 
                <div className='alertmessage'>{!isPasswordValid && <div className='error-message'> !!! </div>}
                </div>
              </div>
              <div className='forgotPassText' onClick={() => handleLinkClick('pass')}> 
                Forgot password?
              </div> 
            </div>
            <div className='loginButtonBlock'> 
              {isMessage !== '' &&  (<div className='signInAlert'> 
                {isMessage}
              </div>)}
              <button className='showAll_button' onClick={handleLoginClick}>
                <div className='signInButton'>
                  Sign In 
                </div> 
              </button>
              <div className='loginButtonBlockText'> or </div>
              <button className='showAll_button' onClick={() => handleLinkClick('create')}>
                <div className='createAccount'>
                  Create Account
                </div> 
              </button>
            </div>
          </div> 
        </div>
      </div>
    </div>
  );
};
