import React, {useState, useEffect} from 'react'  
import MyStudioImages from '../components/MyStudioImages';     
import { useNavigate,useLocation } from 'react-router-dom';
import { useData } from '../config/DataContext'; 
 
export default function MyStudio ()  {
  return (  
      <MainComponent /> 
  );
};

const MainComponent = () => {   
  const [UserIdQuery, setUserIdQuery] = useState(1);
  const [favoriQuery, setfavoriQuery] = useState(false); 
  const [status, setStatus] = useState('start_loading');  
  const [refresh, setRefresh] = useState(null); 
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesFull, setSelectedImagesFull] = useState([]);

  const [selectedImagesD, setSelectedImagesD] = useState([]);
  const [selectedImagesC, setSelectedImagesC] = useState([]);

  const [deleteImagesC, setDeleteImagesC] = useState([]);
  const [deleteImagesD, setDeleteImagesD] = useState([]);
  const [updateImages, setUpdateImages] = useState([]);
  const configData = useData(); 
  const navigate  = useNavigate(); 
  const location = useLocation();

  useEffect(() => {
    document.title = "YOUNIQ - My Studio";
  }, []);

  const { combinedImagesselect = [],editTitle,editDescription,editCategory,editColor,editDesignedFor ,editcreationId ,editstatus,editcreationStatus, editNote} = location.state || {};  

    const handleDetailsClick = () => {  
      let combinedImages = []; 
      combinedImages =  [...selectedImagesFull];   
      localStorage.removeItem('selectedImages');
      localStorage.removeItem('selectedImagesFull');
      if (!combinedImages.length>0)
        return setStatus('no_select');

        navigate('/MyStudioDetails', { 
          state: { 
            combinedImages,
            editTitle,
            editDescription,
            editCategory,
            editColor,
            editDesignedFor,
            editcreationId,
            editstatus: 'edit_creation',
            editcreationStatus,
            editNote
          }
        });
  
    };
 
    useEffect(() => { 
      setStatus(editstatus);   
    }, [editstatus,navigate, location.pathname ]);
   
    useEffect(() => {
      setUpdateImages(selectedImages);  
    }, [selectedImages]);

    const handleSubmit = () => { 
      setfavoriQuery(favoriQuery ? false : true); 
    };
  
    const handleStatusChange = (sImages, collectionType, sImagesFull,delImageID) => {
      
      const savedSelectedImages =  JSON.parse(localStorage.getItem('selectedImages')) || [];
      const savedSelectedImagesFull =   JSON.parse(localStorage.getItem('selectedImagesFull')) || [];
   
      const sImagesFullArray = Array.isArray(sImagesFull) ? sImagesFull : [];
 
      let combinedSelectedImages =  [...new Set([...savedSelectedImages])] ;
      //alert (savedSelectedImages)
      
      sImages.forEach(image => {
        if (!combinedSelectedImages.some(savedImage => savedImage === image)) {
          combinedSelectedImages.push(image);
        }
      });
       
      if (delImageID > 0) {
        combinedSelectedImages = combinedSelectedImages.filter(imageID => imageID !== delImageID);
      }
  
      setSelectedImages(combinedSelectedImages);
      if (collectionType ==='CustomImages')
        setSelectedImagesC(combinedSelectedImages);
      else if (collectionType ==='DesignImages')
        setSelectedImagesD(combinedSelectedImages);

 
      let updatedSelectedImagesFull = sImagesFullArray.filter(image => !deleteImagesC.includes(image.id) && !deleteImagesD.includes(image.id)  ); 
      let combinedSelectedImagesFull = [...savedSelectedImagesFull];
      updatedSelectedImagesFull.forEach(image => {
        if (!combinedSelectedImagesFull.some(savedImage => savedImage.id === image.id)) {
          combinedSelectedImagesFull.push(image);
        }
      }); 
       
      if (delImageID > 0) {
        combinedSelectedImagesFull = combinedSelectedImagesFull.filter(image => image.id !== delImageID); 
      }
       
      setSelectedImagesFull(combinedSelectedImagesFull);
 
      localStorage.removeItem('selectedImages');
      localStorage.removeItem('selectedImagesFull');

       localStorage.setItem('selectedImages', JSON.stringify(combinedSelectedImages));
       localStorage.setItem('selectedImagesFull', JSON.stringify(combinedSelectedImagesFull));
   
      let newStatus = (combinedImagesselect.length === 0 && combinedSelectedImages.length > 0) ? 'delete_post' : 'start_loading'; 
      setStatus(newStatus);
    };
   
    const handleDeleteClick = (e) => { 
      const requestBodyCustom = JSON.stringify(selectedImagesC);  
      const requestBodyDesign= JSON.stringify(selectedImagesD);  
      
      let token = localStorage.getItem('Authorization');
      try {
          if (selectedImagesC.length>0) 
          {
            const responseCustom =  fetch(`${configData.apiUrl}User/DeleteCustomImages`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token 
              },
              body: requestBodyCustom,
            });
  
            if (responseCustom) { 
              console.log('Files delete successfully'); 
              
            } else {
              console.error('Error delete files');
            }
            
            setDeleteImagesC(selectedImagesC);
            setRefresh(selectedImagesC); 
           
          } 

          if (selectedImagesD.length>0) 
          { 
            const responseDesign =  fetch(`${configData.apiUrl}Design/DeleteImages`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token 
              },
              body: requestBodyDesign,
            });
  
            if (responseDesign) { 
              console.log('Files delete successfully'); 
            
            } else {
              console.error('Error delete files');
            } 
            setDeleteImagesD(selectedImagesD);
            setRefresh(selectedImagesD); 
          
          }
    
      } 
      catch (error) {
          console.error('Error delete files', error);
      } 
  
    };

    useEffect(() => { 
      localStorage.removeItem('selectedImages');
      localStorage.removeItem('selectedImagesFull');
      setSelectedImagesFull([]);
      setSelectedImages([]);
      setSelectedImagesC([]);
      setSelectedImagesD([]);
      setStatus('start_loading');
    
    }, [refresh]);
  
    const handleMyCreationClick = () => {  
      navigate('/MyCreation'); 
    };
 
  return (  
    <div className='content' >   
      <div className='MyStudioContent'>
  
      { !editstatus  ? 
       (<div className='navbar'>
          <button onClick={handleDetailsClick} className='showAll_button'>
            <div className='button_createpost'>
                <div className='button_createpost_appIcon'>
                    <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 40 40" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20 10C21.1046 10 22 10.8954 22 12V18L28 18C29.1046 18 30 18.8954 30 20C30 21.1046 29.1046 22 28 22H22V28C22 29.1046 21.1046 30 20 30C18.8954 30 18 29.1046 18 28V22H12C10.8954 22 10 21.1046 10 20C10 18.8954 10.8954 18 12 18L18 18V12C18 10.8954 18.8954 10 20 10Z" fill="#FEF6F6"/>
                    </svg>
                </div>
                <div className='button_createpost_label'>
                    Submit Creation
                </div>
            </div> 
          </button>
        {status ==='start_loading' ?
        (<div className='button_favorites'>
            <div className='button_favorites_appIcon'>
              <button type="submit"   onClick={handleSubmit}  >
                {favoriQuery?   
                  (<svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 30 30" fill="none">
                    <g clip-path="url(#clip0_114_186)">
                        <path d="M14.049 3.42664C14.3483 2.50537 15.6517 2.50537 15.951 3.42664L18.0312 9.82794C18.1651 10.2399 18.549 10.5189 18.9822 10.5189L25.713 10.5191C26.6817 10.5192 27.0845 11.7587 26.3008 12.3281L20.8556 16.2846C20.5052 16.5392 20.3585 16.9906 20.4924 17.4026L22.5721 23.804C22.8714 24.7253 21.8169 25.4914 21.0332 24.9221L15.5878 20.966C15.2373 20.7114 14.7627 20.7114 14.4122 20.966L8.96676 24.9221C8.18306 25.4914 7.12863 24.7253 7.42794 23.804L9.50763 17.4026C9.64148 16.9906 9.49483 16.5392 9.14438 16.2846L3.69919 12.3281C2.91553 11.7587 3.31828 10.5192 4.28697 10.5191L11.0178 10.5189C11.451 10.5189 11.8349 10.2399 11.9688 9.82794L14.049 3.42664Z" fill="white"  stroke="white" stroke-width="2"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_114_186">
                        <rect width="29" height="29" fill="white" transform="translate(0.5 0.5)"/>
                        </clipPath>
                    </defs>
                    </svg>)
                    :(<svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 30 30" fill="none">
                      <g clip-path="url(#clip0_114_186)">
                          <path d="M14.049 3.42664C14.3483 2.50537 15.6517 2.50537 15.951 3.42664L18.0312 9.82794C18.1651 10.2399 18.549 10.5189 18.9822 10.5189L25.713 10.5191C26.6817 10.5192 27.0845 11.7587 26.3008 12.3281L20.8556 16.2846C20.5052 16.5392 20.3585 16.9906 20.4924 17.4026L22.5721 23.804C22.8714 24.7253 21.8169 25.4914 21.0332 24.9221L15.5878 20.966C15.2373 20.7114 14.7627 20.7114 14.4122 20.966L8.96676 24.9221C8.18306 25.4914 7.12863 24.7253 7.42794 23.804L9.50763 17.4026C9.64148 16.9906 9.49483 16.5392 9.14438 16.2846L3.69919 12.3281C2.91553 11.7587 3.31828 10.5192 4.28697 10.5191L11.0178 10.5189C11.451 10.5189 11.8349 10.2399 11.9688 9.82794L14.049 3.42664Z" fill="#383838" stroke="white" stroke-width="2"/>
                      </g>
                      <defs>
                          <clipPath id="clip0_114_186">
                          <rect width="29" height="29" fill="white" transform="translate(0.5 0.5)"/>
                          </clipPath>
                      </defs>
                      </svg>)}
              </button>
            
            </div>
            <div className='button_favorites_label'  onClick={handleSubmit}> 
                Favorites 
            </div>
          </div> ) : (<div></div>)}
        {status ==='start_loading' && (
            <button  className='showAll_button' onClick={handleMyCreationClick} >
              <div className='button_myposts_creations'>
                  <div className='button_myposts_creations_appIcon'>
                      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 29 30" fill="none">
                      <path d="M7.25001 4.85001C5.64838 4.85001 4.35001 6.14838 4.35001 7.75001V10.65C4.35001 12.2516 5.64838 13.55 7.25001 13.55H10.15C11.7516 13.55 13.05 12.2516 13.05 10.65V7.75001C13.05 6.14838 11.7516 4.85001 10.15 4.85001H7.25001Z" fill="#FEF6F6"/>
                      <path d="M7.25001 16.45C5.64838 16.45 4.35001 17.7484 4.35001 19.35V22.25C4.35001 23.8516 5.64838 25.15 7.25001 25.15H10.15C11.7516 25.15 13.05 23.8516 13.05 22.25V19.35C13.05 17.7484 11.7516 16.45 10.15 16.45H7.25001Z" fill="#FEF6F6"/>
                      <path d="M15.95 7.75001C15.95 6.14838 17.2484 4.85001 18.85 4.85001H21.75C23.3516 4.85001 24.65 6.14838 24.65 7.75001V10.65C24.65 12.2516 23.3516 13.55 21.75 13.55H18.85C17.2484 13.55 15.95 12.2516 15.95 10.65V7.75001Z" fill="#FEF6F6"/>
                      <path d="M15.95 19.35C15.95 17.7484 17.2484 16.45 18.85 16.45H21.75C23.3516 16.45 24.65 17.7484 24.65 19.35V22.25C24.65 23.8516 23.3516 25.15 21.75 25.15H18.85C17.2484 25.15 15.95 23.8516 15.95 22.25V19.35Z" fill="#FEF6F6"/>
                      </svg>
                    </div>
                <div className='button_myposts_creations_label'>
                    My Creations
                </div>
              </div> 
            </button>)   }
         {status ==='delete_post' && (  
            <div>
              <button onClick={handleDeleteClick} className='showAll_button'>
                <div className='delete_button'>
                  <div className='button_delete_appIcon'>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 28 32" fill="none">
                      <path d="M25.3888 7.94444L23.9915 27.5074C23.871 29.1936 22.468 30.5 20.7775 30.5H7.44466C5.75416 30.5 4.35107 29.1936 4.23063 27.5074L2.83328 7.94444M10.8888 14.3889V24.0556M17.3333 14.3889V24.0556M18.9444 7.94444V3.11111C18.9444 2.22132 18.2231 1.5 17.3333 1.5H10.8888C9.99904 1.5 9.27772 2.22132 9.27772 3.11111V7.94444M1.22217 7.94444H26.9999" stroke="#FF453A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div className='delete_button_Text'>
                    Delete 
                  </div>
                </div> 
              </button>
            </div>)  }

          {status ==='no_select' && (  
            <div>
              <button  className='showAll_button' onClick={handleDetailsClick} >
                <div className='button_myposts_creations'>
                  <div className='button_myposts_creations_label'>
                        Next
                  </div>
                    <div className='button_next_appIcon'>
                      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 35 36" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0126 9.76256C18.696 9.07915 19.804 9.07915 20.4874 9.76256L27.4874 16.7626C28.1709 17.446 28.1709 18.554 27.4874 19.2374L20.4874 26.2374C19.804 26.9209 18.696 26.9209 18.0126 26.2374C17.3291 25.554 17.3291 24.446 18.0126 23.7626L22.0251 19.75L8.75 19.75C7.7835 19.75 7 18.9665 7 18C7 17.0335 7.7835 16.25 8.75 16.25H22.0251L18.0126 12.2374C17.3291 11.554 17.3291 10.446 18.0126 9.76256Z" fill="#FEF6F6"/>
                      </svg>
                    </div>
                  
                 </div> 
              </button>
            </div>)  
          } 
        </div>) :(
          <div className='createpost_editselection'>
            <button onClick={handleDetailsClick} className='showAll_button'>
              <div className='button_createpost'> 
                <div className='button_createpost_label'>
                  Save & resume
                </div>
                <div className='button_back_appIcon'>
                    <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 36 36" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5126 9.76256C19.196 9.07915 20.304 9.07915 20.9874 9.76256L27.9874 16.7626C28.6709 17.446 28.6709 18.554 27.9874 19.2374L20.9874 26.2374C20.304 26.9209 19.196 26.9209 18.5126 26.2374C17.8291 25.554 17.8291 24.446 18.5126 23.7626L22.5251 19.75L9.25 19.75C8.2835 19.75 7.5 18.9665 7.5 18C7.5 17.0335 8.2835 16.25 9.25 16.25H22.5251L18.5126 12.2374C17.8291 11.554 17.8291 10.446 18.5126 9.76256Z" fill="#FEF6F6"/>
                  </svg>
                </div>
              </div> 
            </button>
          </div>)}
        {status ==='no_select' && (
            <div className='warning_mystudioPanel'>
               <div className='warning_mystudioPanel_Text'>
                   Select the content for the post  
                </div>
            </div>
         )}
  
      <div className='table_mystudio'> 
        <MyStudioImages UserIdQuery={UserIdQuery} PlatformQuery='Big' 
        CollectionTypeQuery='CustomImages'   
        OnlyFavoritesQuery={favoriQuery}  ImageCountQuery={5}  onStatusChange={handleStatusChange}  
        refreshImages={refresh} CSelectedImages={combinedImagesselect} DeleteImages={deleteImagesC}  />

      </div>
      <div className='SpaceHeight'> </div>
      <div className='table_mystudio'>
          <MyStudioImages UserIdQuery={UserIdQuery} PlatformQuery='Big' 
          CollectionTypeQuery='DesignImages'   
          OnlyFavoritesQuery={favoriQuery}  ImageCountQuery={5}  onStatusChange={handleStatusChange}  
          refreshImages={refresh} CSelectedImages={combinedImagesselect}  DeleteImages={deleteImagesD} /> 
      </div>
      
    </div>
  </div>
  )
}
