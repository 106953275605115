import React, { useState, useEffect } from 'react'; 
import { useData } from '../config/DataContext'; 
import SpotLightListImages from '../components/SpotLightListImages';    
import Multiselect from 'multiselect-react-dropdown'; 
import { useNavigate } from 'react-router-dom';  
import '../styles/multiselect-custom-SP.css';

export default function SpotLightList() {
  return (  
      <MainComponent /> 
  );
};

const MainComponent = () => {   
  const [selectedOptionsCt, setSelectedOptionsCt] = useState([]);
  const [selectedOptionsCl, setSelectedOptionsCl] = useState([]);
  const [selectedOptionsDs, setSelectedOptionsDs] = useState([]);
  const [FilterArrayCt, setFilterArrayCt] = useState([]);
  const [FilterArrayCl, setFilterArrayCl] = useState([]);
  const [FilterArrayDs, setFilterArrayDs] = useState([]);
  const [UserIdQuery, setUserIdQuery] = useState(1);  
  const [searchText, setSearchText] = useState('');
  const navigate = useNavigate();
  const configData = useData();  



  const [colors, setColors] = useState([]);
  const [designfors, setDesignfors] = useState([]); 
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    document.title = "YOUNIQ - Spotlight List";
  }, []);

  const onDataFetchFiltersCt = (dataArray) => {
    const filteredCategories = dataArray.map(item => ({
      id: item.id,
      name: item.name
    }));
   
    setCategories(filteredCategories);
  };

  const onDataFetchFiltersCl = (dataArray) => {
    const filteredColors = dataArray.map(item => ({
      id: item.id,
      name: item.name
    }));
  
    setColors(filteredColors);
  };

  const onDataFetchFiltersDs = (dataArray) => {
    const filteredDesignFor = dataArray.map(item => ({
      id: item.id,
      name: item.name
    }));
  
    setDesignfors(filteredDesignFor);
  };


  const handleOptionChangeCt = (selectedList) => {
    setSelectedOptionsCt(selectedList);
    setFilterArrayCt(selectedList.map(item => item.value));
  };

  const onRemoveCt = (selectedList) => {
    setSelectedOptionsCt(selectedList);
    setFilterArrayCt(selectedList.map(item => item.value));
  };

  const handleOptionChangeCl = (selectedList) => {
    setSelectedOptionsCl(selectedList);
    setFilterArrayCl(selectedList.map(item => item.value));
  };

  const onRemoveCl = (selectedList) => {
    setSelectedOptionsCl(selectedList);
    setFilterArrayCl(selectedList.map(item => item.value));
  };

  const handleOptionChangeDs = (selectedList) => {
    setSelectedOptionsDs(selectedList);
    setFilterArrayDs(selectedList.map(item => item.value));
  };

  const onRemoveDs = (selectedList) => {
    setSelectedOptionsDs(selectedList);
    setFilterArrayDs(selectedList.map(item => item.value));
  };

  
  const optionsCt = categories.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  
  const optionsCl = colors.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const optionsDs = designfors.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const [shouldRefresh, setShouldRefresh] = useState(false);
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length >= 3) {
      setShouldRefresh(true);
    } else {
      setShouldRefresh(false);
    }
  };

  useEffect(() => {
    if (shouldRefresh) {
     
    }
  }, [shouldRefresh]);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleLinkClick = (path) => {
    let userType = localStorage.getItem('userType'); 
    let userName = localStorage.getItem('userName');
    
    let userPhoto = localStorage.getItem('userPhoto');

    let token = localStorage.getItem('Authorization');
 
   if (isMobile && window.ReactNativeWebView) {
     if (path === "/SpotLight") {
       const payload = JSON.stringify({ action: 'openNativeSpotlight', token, userPhoto, userName, userType });
       window.ReactNativeWebView.postMessage(payload);  
     } else {
       navigate(path);
     }
   } else {
     navigate(path);
   }
 };
 

  return ( 
    <div className='content'>  
      <div className='spotlightContent'>
        <div className='navbar_spotlight'>
          <button className='showAll_button' onClick={() => handleLinkClick('/SpotLight')}>
            <div className='button_createpost'>
              <div className='button_back_appIcon'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 36" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M16.9874 26.2374C16.304 26.9209 15.196 26.9209 14.5126 26.2374L7.51256 19.2374C6.82915 18.554 6.82915 17.446 7.51256 16.7626L14.5126 9.76256C15.196 9.07915 16.304 9.07915 16.9874 9.76256C17.6709 10.446 17.6709 11.554 16.9874 12.2374L12.9749 16.25L26.25 16.25C27.2165 16.25 28 17.0335 28 18C28 18.9665 27.2165 19.75 26.25 19.75H12.9749L16.9874 23.7626C17.6709 24.446 17.6709 25.554 16.9874 26.2374Z" fill="white"/>
                </svg>
              </div>
              <div className='spotlightback_label'>Spotlight</div>
            </div>
          </button>
          <div className='searchBox_SpotlightBar'>
              <div className='searchBox_Spotlight'>
                      <button   className='showAll_button'>
                          <div className='searchIcon_box'> 
                              <div className='searchIcon_App'>
                                  <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 22 22" fill="none">
                                     <path fill-rule="evenodd" clip-rule="evenodd" d="M8.89998 4.7001C6.58038 4.7001 4.69998 6.5805 4.69998 8.9001C4.69998 11.2197 6.58038 13.1001 8.89998 13.1001C11.2196 13.1001 13.1 11.2197 13.1 8.9001C13.1 6.5805 11.2196 4.7001 8.89998 4.7001ZM2.59998 8.9001C2.59998 5.4207 5.42058 2.6001 8.89998 2.6001C12.3794 2.6001 15.2 5.4207 15.2 8.9001C15.2 10.2607 14.7686 11.5206 14.0353 12.5505L19.0924 17.6076C19.5025 18.0177 19.5025 18.6825 19.0924 19.0926C18.6824 19.5026 18.0176 19.5026 17.6075 19.0926L12.5503 14.0354C11.5205 14.7688 10.2606 15.2001 8.89998 15.2001C5.42058 15.2001 2.59998 12.3795 2.59998 8.9001Z" fill="white"/>
                                  </svg>
                              </div>
                          </div>
                      </button>
                      <div className='search_text'> 
                            <input type='text' placeholder='Search' id="search" 
                              value={searchText}
                              onChange={handleSearchChange} />    
                      </div>  
              </div>
            </div>
    
          <div className='navbar_rightSpotlight'>
         
            <div className='mycreationFilterBox'>
              <div className='mycreationText'> 
                Filter by 
              </div>
              <div className='selectFilterSpotlight'>
              <div className="multiselect-wrapper">
                <Multiselect
                  options={optionsCt}
                  selectedValues={selectedOptionsCt}
                  onSelect={handleOptionChangeCt}
                  onRemove={onRemoveCt}
                  displayValue="label"
                  placeholder={`Category`}   
                  closeOnSelect={true}
                  showCheckbox={true}
                  showArrow={true}
                  className="multiselect-without-border custom-multiselect" 
                  hideSelectedList
                  
                />
                <span className="custom-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55754 8.15764C5.96759 7.74759 6.63241 7.74759 7.04246 8.15764L10.5 11.6152L13.9575 8.15764C14.3676 7.74758 15.0324 7.74758 15.4425 8.15764C15.8525 8.56769 15.8525 9.23251 15.4425 9.64256L11.2425 13.8426C10.8324 14.2526 10.1676 14.2526 9.75754 13.8426L5.55754 9.64256C5.14749 9.23251 5.14749 8.56769 5.55754 8.15764Z" fill="#FEF6F6"/>
                    </svg>
                </span>
              </div>

              <div className="multiselect-wrapper">
                <Multiselect
                  options={optionsCl}
                  selectedValues={selectedOptionsCl}
                  onSelect={handleOptionChangeCl}
                  onRemove={onRemoveCl}
                  displayValue="label"
                  placeholder={`Color`}   
                  closeOnSelect={true}
                  showCheckbox={true}
                  showArrow={true}
                  className="multiselect-without-border custom-multiselect" 
                  hideSelectedList 
                  style={{
                    borderRadius: '8px',  
                    border: 'none !important',
                    boxShadow: 'none !important', 
                  }}
                />
                <span className="custom-arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55754 8.15764C5.96759 7.74759 6.63241 7.74759 7.04246 8.15764L10.5 11.6152L13.9575 8.15764C14.3676 7.74758 15.0324 7.74758 15.4425 8.15764C15.8525 8.56769 15.8525 9.23251 15.4425 9.64256L11.2425 13.8426C10.8324 14.2526 10.1676 14.2526 9.75754 13.8426L5.55754 9.64256C5.14749 9.23251 5.14749 8.56769 5.55754 8.15764Z" fill="#FEF6F6"/>
                    </svg>
                </span>
              </div>

              <div className="multiselect-wrapper">
                <Multiselect
                  options={optionsDs}
                  selectedValues={selectedOptionsDs}
                  onSelect={handleOptionChangeDs}
                  onRemove={onRemoveDs}
                  displayValue="label"
                  placeholder={`Designed For`}   
                  closeOnSelect={true}
                  showCheckbox={true}
                  showArrow={true}
                  className="multiselect-without-border custom-multiselect" 
                  hideSelectedList 
                />
                <span className="custom-arrowD">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55754 8.15764C5.96759 7.74759 6.63241 7.74759 7.04246 8.15764L10.5 11.6152L13.9575 8.15764C14.3676 7.74758 15.0324 7.74758 15.4425 8.15764C15.8525 8.56769 15.8525 9.23251 15.4425 9.64256L11.2425 13.8426C10.8324 14.2526 10.1676 14.2526 9.75754 13.8426L5.55754 9.64256C5.14749 9.23251 5.14749 8.56769 5.55754 8.15764Z" fill="#FEF6F6"/>
                    </svg>
                </span>
              </div>
            </div> 
          </div> 
        </div> 
        </div> 
        <SpotLightListImages 
          UserIdQuery={UserIdQuery} 
          PlatformQuery='Big'  
          PageNumberQuery='1' 
          ListSpotlightTypeQuery='All'
          FilterArrayCt={FilterArrayCt}  
          FilterArrayCl={FilterArrayCl}  
          FilterArrayDs={FilterArrayDs}  
          onDataFetchFiltersCt={onDataFetchFiltersCt} 
          onDataFetchFiltersCl={onDataFetchFiltersCl} 
          onDataFetchFiltersDs={onDataFetchFiltersDs} 
          SearchText ={searchText}
        />
      </div>
    </div>
  )
}
