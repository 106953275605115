import React, { useState, useEffect } from 'react';  
import Multiselect from 'multiselect-react-dropdown'; 
import { useNavigate, useLocation } from 'react-router-dom';  
import '../styles/multiselect-custom.css';
import { useData } from '../config/DataContext';  
import FormatDate from '../components/FormatDate'; 

export default function CreationList() {
  return (  
      <MainComponent /> 
  );
};

const MainComponent = () => {   
    const [selectedOptions, setSelectedOptions] = useState([]); 
    const [selectedOptionsCRName, setselectedOptionsCRName] = useState([]); 
    const [statusNames, setStatusNames] = useState([]);
    const [statusNamesFull, setStatusNamesFull] = useState([]);
    const [creatorNames, setcreatorNames] = useState([]);
    const [creatorNamesFull, setcreatorNamesFull] = useState([]);
    const [moveListin, setmoveListin] = useState([]);
    const [selectedmoveListin, setselectedmoveListin] = useState('');
    const [checkedItems, setCheckedItems] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [DataCount, setDataCount] = useState(0);
    const [dataAll, setDataAll] = useState([]);  
    const [currentPage, setCurrentPage] = useState(1);  
    const [totalPages, setTotalPages] = useState(1);   
    //const [pageSize, setpageSize] = useState(15);  
    const configData = useData();  
    const navigate = useNavigate(); 
    let token = localStorage.getItem('Authorization');
    let pageSize=1;

    useEffect(() => {
        document.title = "YOUNIQ - Creations";
      }, []);

      
    const handleSearchChange = (e) => {
      setSearchText(e.target.value);
      fetchData('Main', e.target.value, 1, statusNames, creatorNames);   
    };
 
    const handleSearch = () => { 
        fetchData('Main', searchText, 1, statusNames, creatorNames);   
    };
   
    const onDataFetchFilters = (statusName) => {
        setStatusNamesFull(statusName); 
    };

    const options = statusNamesFull.map((statusName) => ({ 
        value: statusName,
        label: statusName,
    }));
 
    const onDataFetchFiltersUserName = (creatorNames) => {
        setcreatorNamesFull(creatorNames);  
    };

    const optionsCrName = creatorNamesFull.map((creatorName) => ({
        value: creatorName,
        label: creatorName,
    }));
 
    const location = useLocation();
    const refreshData = location.state?.refresh;

    useEffect(() => { 
       // fetchData('Main', '', 1, statusNames, creatorNames);
    }, [refreshData]);  

    useEffect(() => {     
         
        fetchData('Main','',currentPage,statusNames,creatorNames);   
        
    }, [currentPage]);
     
    const fetchData = async (ListStatusQuery,SearchTextQuery,PageNumberQuery,statusQuery,creatorQuery) => {
        try {
    
            let statusParams = '';
            if (statusQuery && statusQuery.length!=0) {    
                statusParams=statusQuery.map(status => `Status=${status}`).join('&');
                statusParams='&' + statusParams;
            }
        
            let creatorParams = '';
            if (creatorQuery && creatorQuery.length!=0) {
                creatorParams = creatorQuery.map(creator => `Users=${creator}`).join('&');
                creatorParams='&' + creatorParams;
            }
    
            const response = await fetch(
                `${configData.apiUrl}Creation/ListCreations?ListIn=${ListStatusQuery}&SearchText=${SearchTextQuery}${statusParams}${creatorParams}&PageNumber=${PageNumberQuery}`,
                {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token 
                },
                }
            );
    
            const data = await response.json();   
            const creations = data.creations || [];
      
            if (Array.isArray(data.statusList)) 
            {
                onDataFetchFilters(data.statusList);
            }
            if (Array.isArray(data.userList)) 
            {
                onDataFetchFiltersUserName(data.userList);
            } 

            if (Array.isArray(data.availableLists)) 
            {
                setmoveListin(data.availableLists);
            }
            pageSize=data.pageSize;
            data.creations = data.creations.map(creation => ({
                ...creation,
                checkStatus: false,
            })) 

            setDataAll(data);   
            onDataCount(data.creationCount);  
            if (data.creationCount>0)
            setTotalPages(Math.ceil(data.creationCount / pageSize));
        
        } catch (error) {
            console.log(error);
        }
    };
 
    const handleListinMoveChange = (event) => { 
        const selectedValue = event.target.value; 
        setselectedmoveListin(selectedValue); 
        if (checkedItems && checkedItems.length>0)
        {
            handleListinSubmit(selectedValue);
            setCheckedItems([]);
        } 
    };

    const handleListinChange = (event) => { 
        const selectedValue = event.target.value; 
        fetchData(selectedValue, '', 1, statusNames, creatorNames);    
    };
    
    const handleOptionChange = (selectedList) => { 
        setSelectedOptions(selectedList); 
        setStatusNames(selectedList.map(item => item.value));
        fetchData('Main','',1,selectedList.map(item => item.value),creatorNames);    
    };

    const onRemove = (selectedList) => { 
        setSelectedOptions(selectedList);
        setStatusNames(selectedList.map(item => item.value));
        fetchData('Main','',1,selectedList.map(item => item.value),creatorNames);   
    };

    const handleOptionChangeCrName = (selectedList) => { 
        setselectedOptionsCRName(selectedList); 
        setcreatorNames(selectedList.map(item => item.value));
        fetchData('Main','',1, statusNames, selectedList.map(item => item.value));   
    };

    const onRemoveCrName = (selectedList) => { 
        setselectedOptionsCRName(selectedList); 
        setcreatorNames(selectedList.map(item => item.value));
        fetchData('Main','', 1, statusNames, selectedList.map(item => item.value));   
    };

    const onDataCount = (count) => {
        setDataCount(count);
    };
  
    const getStatusColor = (status) => {
        switch (status) {
            case 'AwaitingApproval':
                return '#FFFFFF'; 
            case 'Spotlight':
                return '#EBAB00';
            case 'Gallery':
                return '#0D99FF';
            case 'ChangeRequested':
                return '#F24822';
            case 'Approved' :
                return '#14AE5C'; 
        }
    };
    
    const handleDetailsClick = (id, creatorName) => {  
        navigate('/CreationListDetails', { state: { creationId : id, creator : creatorName} });
    }

    const handleUserClick = (id, creatorName) => {  
        navigate('/UserProfile', { state: { creatorId : id, creator : creatorName} });
    }
 
    const handleCheckboxChange = (id, isChecked) => {
        let checkedUpdate;

        if (checkedItems.includes(id) && isChecked==false) { 
            checkedUpdate = checkedItems.filter(Id => Id !== id); 
        } 
        else if (!checkedItems.includes(id) && isChecked==true)
        {
            checkedUpdate = [...checkedItems, id]; 
        } 

        setCheckedItems(checkedUpdate);
 
        const checkeddata = dataAll.creations.map(creation => ({
            ...creation,
            checkStatus: checkedUpdate.includes(creation.id),
        }));

        dataAll.creations = checkeddata;
        setDataAll(dataAll); 
          
    };

    const handleListinSubmit = (selectedValue) => { 
        
        const requestBody = Array.from(checkedItems); 
        try {
            const response =  fetch(`${configData.apiUrl}Creation/MoveToList?moveTo=${selectedValue}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json', 
                'Authorization': token 
              },
              body: JSON.stringify(requestBody),
            }).then((response) => response.json());
            if (response) 
            {
                setCheckedItems(new Set());
            }
            console.log(JSON.stringify(requestBody));
        } 
          catch (error) {
            console.error('', error); 
        };
     
       navigate(0);
    };

    const renderData = (data) => { 
        if (!data || !data.creations) {
            return <div>No data available</div>;
        }
        if (checkedItems && checkedItems.length>0)
        {
            const checkeddata = dataAll.creations.map(creation => ({
            ...creation,
            checkStatus: checkedItems.includes(creation.id),
            }));
    
            data.creations =checkeddata;
        } 
        const creations = data.creations || [];

        return creations.map((creation) => (  
            <div className='tableCreationRow'> 
                <div className='tableColumnLeft' >
                    <div className='tableColumnCheck'>   
                            <div className='checkbox_icon_adm'>
                                <label className="checkbox_container_adm">
                                    <input
                                        type="checkbox"
                                        className='checkbox_custom' 
                                        id={`checkbox-${creation.id}`}
                                        checked={creation.checkStatus}
                                        onChange={(e) => handleCheckboxChange(creation.id,  e.target.checked)}
                                    />
                                    <span className="checkbox_label"> </span>
                                    <label htmlFor={`checkbox-${creation.id}`}></label>
                                </label>
                            </div>
                            <div className='tableColumnText'>
                               <a  onClick={() => handleDetailsClick(creation.id, creation.userProfile.name)} > {creation.title} </a>
                            </div>  
                    </div>

                    <div className='tableColumn'>  
                        <div className='tableColumnText'>
                        <a  onClick={() => handleUserClick(creation.userId, creation.userProfile.name)} >   {creation.userProfile.name}</a>
                        </div> 
                    </div>    

                    <div className='tableColumn'> 
                        <div className='tableColumnText' style={{ color: getStatusColor(creation.status) }}>
                            {creation.status}
                        </div> 
                    </div> 

                    <div className='tableColumn'> 
                        <div className='tableColumnText'>
                            { FormatDate(creation.createdAt) }
                        </div> 
                    </div>   
                </div>

                <div className='tableColumnRight' >
                    <div className='tableColumnCenter'> 
                        <div className='tableColumnBackBlack'>
                            <div className='tableColumnBackBlack_text'> 
                                {creation.spotlight && <a href={configData.webUrl + creation.spotlight.linkUrl}>{configData.webUrl + creation.spotlight.linkUrl}</a>} 
                            </div>
                        </div>
                            
                    </div>   
                    <div className='tableColumnCenter'>  
                        <div className='tableColumnBackBlack'>
                            <div className='tableColumnBackBlack_text'>
                                {creation.spotlight && <a href={creation.spotlight.shopifyLinkUrl}>{creation.spotlight.shopifyLinkUrl}</a>}
                            </div>
                        </div> 
                    </div>      
                </div>
        </div>
                 
        )) 
    };
    
    const handlePageChange = (newPage) => {
        if (newPage < 1 || newPage > totalPages) return;
        setCurrentPage(newPage); 
    };

    const renderPagination = () => {
        const pageIcons = [...Array(totalPages).keys()].map(i => (
          <button 
            key={i}
            className={`page-icon ${currentPage === i + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(i + 1)}
            style={{ background: 'transparent', border: 'none' }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12" height="12">
              <circle cx="12" cy="12" r="10" fill={currentPage === i + 1 ? '#fff' : '#5E5E5E'} />
            </svg>
          </button>
        ));
    
        return (
          <> 
            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className='showAll_button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3424 5.55754C13.7525 5.96759 13.7525 6.63241 13.3424 7.04246L9.8849 10.5L13.3424 13.9575C13.7525 14.3676 13.7525 15.0324 13.3424 15.4425C12.9324 15.8525 12.2676 15.8525 11.8575 15.4425L7.65751 11.2425C7.24746 10.8324 7.24746 10.1676 7.65751 9.75754L11.8575 5.55754C12.2676 5.14749 12.9324 5.14749 13.3424 5.55754Z" fill="white"/>
                </svg>
            </button>
            {pageIcons}
            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className='showAll_button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.65751 15.4425C7.24746 15.0324 7.24746 14.3676 7.65751 13.9575L11.1151 10.5L7.65751 7.04246C7.24746 6.63241 7.24746 5.96759 7.65751 5.55754C8.06756 5.14749 8.73239 5.14749 9.14244 5.55754L13.3424 9.75754C13.7525 10.1676 13.7525 10.8324 13.3424 11.2425L9.14244 15.4425C8.73239 15.8525 8.06756 15.8525 7.65751 15.4425Z" fill="white"/>
                </svg>
            </button>
            </> 
        );
      };
    

  return ( 
    <div className='content'>  
      <div className='MyCreationListContent'>
        <div className='navbar_Admin'> 
            <div className='Listin'  style={{ display: checkedItems.length == 0 ? 'inline-block' : 'none' }}>
                <select id="selectListin"  onChange={handleListinChange}   >
                    <option value='All' > All </option>
                    <option value='Main' selected> Main </option>
                    <option value='Archive' > Archive </option>           
                    <option value='Trash' > Trash </option>             
                </select> 
                
            </div>
            <div className='button_archive' style={{ display: checkedItems.length > 0 ? 'inline-block' : 'none' }} >
                <select id="saveListin"  onChange={handleListinMoveChange}  >
                <option value='0' > Send to... </option>
                    {moveListin.map(list => (
                        <option key={list} value={list}   >
                                {list}
                        </option>
                    ))}
                </select>
            </div>
           
         
                <div className='navbar_right'>
                           
                <div className='searchBox_SpotlightBar'>
                    <div className='searchBox_Creation'>
                        <button onClick={handleSearch} className='showAll_button'>
                            <div className='searchIcon_box'> 
                                <div className='searchIcon_App'>
                                    <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 22 22" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.89998 4.7001C6.58038 4.7001 4.69998 6.5805 4.69998 8.9001C4.69998 11.2197 6.58038 13.1001 8.89998 13.1001C11.2196 13.1001 13.1 11.2197 13.1 8.9001C13.1 6.5805 11.2196 4.7001 8.89998 4.7001ZM2.59998 8.9001C2.59998 5.4207 5.42058 2.6001 8.89998 2.6001C12.3794 2.6001 15.2 5.4207 15.2 8.9001C15.2 10.2607 14.7686 11.5206 14.0353 12.5505L19.0924 17.6076C19.5025 18.0177 19.5025 18.6825 19.0924 19.0926C18.6824 19.5026 18.0176 19.5026 17.6075 19.0926L12.5503 14.0354C11.5205 14.7688 10.2606 15.2001 8.89998 15.2001C5.42058 15.2001 2.59998 12.3795 2.59998 8.9001Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </button>
                        <div className='search_text'> 
                            <input type='text' placeholder='Search' id="search" 
                               value={searchText}
                               onChange={handleSearchChange} />    
                        </div> 
                        
                    </div>
                </div>
                    <div className='mycreationFilterBox'>
                        <div className='mycreationText'> 
                            Filter by 
                        </div>
                        <div>
                            <Multiselect
                                options={options}
                                selectedValues={selectedOptions}
                                onSelect={handleOptionChange}
                                onRemove={onRemove}
                                displayValue="label"
                                placeholder={`ALL (${DataCount})`}   
                                closeOnSelect={true}
                                showCheckbox={true}
                                className="multiselect-container" 
                                showArrow={true}
                                hideSelectedList
                                style={{
                                borderRadius: '8px',  
                                border: 'none'    
                                }}
                            />
                                <span className="A_custom-arrowS">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55754 8.15764C5.96759 7.74759 6.63241 7.74759 7.04246 8.15764L10.5 11.6152L13.9575 8.15764C14.3676 7.74758 15.0324 7.74758 15.4425 8.15764C15.8525 8.56769 15.8525 9.23251 15.4425 9.64256L11.2425 13.8426C10.8324 14.2526 10.1676 14.2526 9.75754 13.8426L5.55754 9.64256C5.14749 9.23251 5.14749 8.56769 5.55754 8.15764Z" fill="#FEF6F6"/>
                                    </svg>
                                </span>
                        </div>
                        <div>
                            <Multiselect
                                options={optionsCrName}
                                selectedValues={selectedOptionsCRName}
                                onSelect={handleOptionChangeCrName}
                                onRemove={onRemoveCrName}
                                displayValue="label"
                                placeholder={`ALL (${DataCount})`}   
                                closeOnSelect={true}
                                showCheckbox={true}
                                className="multiselect-container" 
                                showArrow={true}
                                hideSelectedList
                                style={{
                                borderRadius: '8px',  
                                border: 'none'   
                                }}
                            />
                              <span className="A_custom-arrowC">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55754 8.15764C5.96759 7.74759 6.63241 7.74759 7.04246 8.15764L10.5 11.6152L13.9575 8.15764C14.3676 7.74758 15.0324 7.74758 15.4425 8.15764C15.8525 8.56769 15.8525 9.23251 15.4425 9.64256L11.2425 13.8426C10.8324 14.2526 10.1676 14.2526 9.75754 13.8426L5.55754 9.64256C5.14749 9.23251 5.14749 8.56769 5.55754 8.15764Z" fill="#FEF6F6"/>
                                    </svg>
                              </span>
                        </div>
                    </div> 
            </div>
        </div>
            <div className='tableCreationList'>
                <div className='tableCreationPostBar'>
                    <div className='tableColumnLeft' >
                        <div className='tableColumnTitle'>
                                TITLE 
                        </div>
                        <div className='tableColumn'>
                                CREATOR
                        </div>
                        <div className='tableColumn'>
                                STATUS
                        </div>
                        <div className='tableColumn'>
                                DATE (UTC)
                        </div>
                    </div>
                    <div className='tableColumnRight' >
                        <div className='tableColumnCenter' >
                                SPOTLIGHT LINK
                        </div>
                        <div className='tableColumnCenter'>
                                SHOPIFY LINK
                        </div>
                    </div>
                </div>
                    
                    {renderData(dataAll)}

                    <div className='tableCreationPageBar'>
                            <div className='tableCreationPageControl'>
                                {renderPagination()}
                            </div>
                    
                    </div>

            </div> 
        </div>
    </div>
  )
}
