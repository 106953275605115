import React, { useState, useEffect } from 'react'; 
import { useData } from '../config/DataContext'; 
import Modal from 'react-modal';
import ImagePopup from '../components/ImagePopup';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '../components/Alert';

 
Modal.setAppElement('#root');  

export default function MyStudioDetails ()  {
  return <MainComponent />;
}

const MainComponent = () => {  
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [designedFor, setDesignedFor] = useState([]); 
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedColor, setselectedColor] = useState('');
  const [selectedDesignedFor, setselectedDesignedFor] = useState('');
  const [SCreationStatus, setSCreationStatus] = useState('edit_mystudio');
  const [savedRequest, setsavedRequest] = useState('');
  const [dataAllDraft, setdataAllDraft] = useState([]);       
  const [Description, setDescription] = useState('');
  const [Title, setTitle] = useState(''); 
  const [isCategoryValid, setIsCategoryValid] = useState(true); 
  const [isColorValid, setisColorValid] = useState(true);
  const [isDesignedForValid, setisDesignedForValid] = useState(true);
  const [isTitleValid, setisTitleValid] = useState(true);
  const [isDescriptionValid, setisDescriptionValid] = useState(true); 
  const [imagecounts, setimagecounts] = useState(0); 
  const [isModalOpenImage, setisModalOpenImage] = useState(false);
  const [isModalImageId, setisModalImageId] = useState(0); 
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [isTitleDelete, setisTitleDelete] = useState('');
  const [isMessageDelete, setisMessageDelete] = useState('');

  const navigate = useNavigate(); 
  const location = useLocation();
  const configData = useData(); 
  let token = localStorage.getItem('Authorization');
  
  useEffect(() => {
    document.title = "YOUNIQ - My Studio Details";
  }, []);

  const openModalImage = (imageId) => {
    setisModalImageId(imageId);
    setisModalOpenImage(true);
  };

  const closeModal = () => {
    setIsModalOpen(false); 
  };

  const openModal = () => {
    setisTitleDelete('Delete the file?');
    setisMessageDelete('This action can’t be undone.');
    setIsModalOpen(true); 
  };

  const maxLength = 500;
  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setDescription(value);
    }
    setisDescriptionValid(true);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setisTitleValid(true);
  };
  const clearText = () => {
    setTitle(''); 
  };
  
  useEffect(() => { 

    fillCategories();
    if(editcreationStatus)
      setSCreationStatus(editcreationStatus);  
      setimagecounts(combinedImages.length);   
     
  }, [configData.apiUrl]);


  const fillCategories = async () => {
    const token = localStorage.getItem('Authorization');  
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization':  token 
    };
    
    try {
      const categoryResponse = await fetch(`${configData.apiUrl}Common/GetCategoryItems?categoryName=Category`, {
        method: 'GET',
        headers: headers
      });
      const categoryData = await categoryResponse.json();
      setCategories(categoryData);
    
      const colorResponse = await fetch(`${configData.apiUrl}Common/GetCategoryItems?categoryName=Color`, {
        method: 'GET',
        headers: headers
      });
      const colorData = await colorResponse.json();
      setColors(colorData);
    
      const designedForResponse = await fetch(`${configData.apiUrl}Common/GetCategoryItems?categoryName=DesignedFor`, {
        method: 'GET',
        headers: headers
      });
      const designedForData = await designedForResponse.json();
      setDesignedFor(designedForData);
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  const { combinedImages = [], editTitle,editDescription,editCategory,editColor,editDesignedFor,editcreationId,editstatus,editcreationStatus,editNote } = location.state || {};  
 
  useEffect(() => {    
    if(editcreationStatus)
      setSCreationStatus(editcreationStatus); 
    if (editTitle)
      setTitle(editTitle);
    if(editDescription)
      setDescription(editDescription);
    if(editCategory)
      setSelectedCategory(editCategory);
    if(editColor)
      setselectedColor(editColor);
    if(editDesignedFor)
      setselectedDesignedFor(editDesignedFor);
    if(editNote)
      setsavedRequest(editNote);
    
 
  }, [editTitle,editDescription,editCategory,editColor,editDesignedFor,editcreationId,editstatus, editcreationStatus,editNote,navigate, location.pathname]);

  
  const { creationId, creationStatus } = location.state || {};

  useEffect(() => { 
    if (creationId)
    {       
      setSCreationStatus(creationStatus);   
      if (creationStatus=='Draft' || creationStatus=='AwaitingApproval'|| creationStatus=='ChangeRequested' || creationStatus=='Approved')
        fetchData('Big');
    }
 
  }, [creationId, creationStatus, navigate, location.pathname]);
 
  const fetchData = async (PlatformQuery) => {

    try { 
      const response = await fetch(
        `${configData.apiUrl}Creation/GetCreation?id=${creationId}&platform=${PlatformQuery}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
        }
      );
      const data = await response.json();     
      if (data)
      { 
        setTitle(data.title);
        setDescription(data.description);
        setsavedRequest(data.note);
       // console.log(JSON.stringify(data));
        if (Array.isArray(data.images)) {
          setdataAllDraft(data); 
          setimagecounts(data.images.length);
          
        } else { 
          console.log('data.images is not an array or is undefined');
          setdataAllDraft(data);    
          setimagecounts(0);   
        } 
    
        if (Array.isArray(data.categoryItems)) 
          {
            const categoryItem = data.categoryItems.find(item => item.category === 'Category');
            const colorItem = data.categoryItems.find(item => item.category === 'Color');
            const designedForItem = data.categoryItems.find(item => item.category === 'DesignedFor'); 
            setSelectedCategory(categoryItem ? categoryItem.id : ''); 
            setselectedColor(colorItem ? colorItem.id : '');
            setselectedDesignedFor(designedForItem ? designedForItem.id : '');
          } 
          else 
          {
            console.log('data.categoryItems is not an array or is undefined');
          }
      }
    
    } catch (error) {
      console.log(error);
    }
  };

  const handleMystudioClick = () => { 
    navigate(location.pathname, { replace: true, state: {} });
    navigate('/MyStudio'); 
  };

  const handleMyCreationClick = () => { 
    navigate(location.pathname, { replace: true, state: {} });
    navigate('/MyCreation'); 
  };
 
  const handleEditSelectionClick = () => { 
    let combinedImagesselect ;
    
    if (!creationId)
      combinedImagesselect = [...combinedImages];
    else 
      combinedImagesselect= [...dataAllDraft.images]; 
      navigate('/MyStudio', { state: { combinedImagesselect, editTitle : Title, editDescription:Description,
      editCategory :selectedCategory,editColor: selectedColor,editDesignedFor : selectedDesignedFor,
      editcreationId : creationId,editstatus: 'edit_selection', editcreationStatus:SCreationStatus, editNote: savedRequest} }); 
      
  };
   
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setIsCategoryValid(true);
  };
   
  const handleColorChange = (event) => {
    setselectedColor(event.target.value);
    setisColorValid(true);
  };
  
  const handleDesignedForChange = (event) => {
    setselectedDesignedFor(event.target.value);
    setisDesignedForValid(true);
  };

  const handleSubmitClick = (actions) => () => {
   
    if (actions !== 'SaveAsDraft' && selectedCategory === '') { 
      setIsCategoryValid(false);
      return;
    }
    if (actions !== 'SaveAsDraft' && selectedColor === '') { 
      setisColorValid(false);
      return;
    }

    if (actions !== 'SaveAsDraft' && selectedDesignedFor === '') { 
      setisDesignedForValid(false);
      return;
    }

    if (Title=='')
    { 
      setisTitleValid(false);
      return;
    }
    if (Description=='')
    { 
     // setisDescriptionValid(false);
     // return;
    }
    let UserId =1;
    const ids = combinedImages.map(image => image.id);
    let CIids = [];
    if (selectedCategory)
    CIids.push(selectedCategory);
    if (selectedColor)
    CIids.push(selectedColor);
    if (selectedDesignedFor)
    CIids.push(selectedDesignedFor); 
    let requestBody={} ;
    let isTitle ='SUBMISSION SENT' ;
    let isMessage ='Your submission will be reviewed shortly.';
    let isStatus ='Submission';
    if (actions =='SaveAsDraft') 
    {
        isTitle ='DRAFT SAVED' ;
        isStatus ='Draft';
        isMessage ='Find it on “Pending & Drafts” section to continue editing it.'; 
    }  
  

    let saveCreationID = 0;
    if (creationId)
      saveCreationID=creationId;
    else if (editcreationId) saveCreationID =editcreationId;
        
    if (saveCreationID===0)
    {
      requestBody = {
        userId: UserId, 
        imageIds: ids, 
        title : Title,
        description : Description
      };
  
      
      if (CIids.length>0) {
        requestBody.categoryItemIds = CIids;
      }
      if (actions =='Submit')
        requestBody.action = actions;

      try {
        const response =  fetch(`${configData.apiUrl}Creation/AddCreation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', 
            'Authorization': token 
          },
          body: JSON.stringify(requestBody),
        }).then((response) => response.json());
        if (response) 
         console.log(JSON.stringify(requestBody));
      } 
      catch (error) {
        console.error('', error); 
        
      };
     } 
     else 
     {
       const requestBodyUpdate = {
          id : saveCreationID,
          userId: UserId,    
        };
        if (Title !== '') {
          requestBodyUpdate.title = Title;
        }
        if (Description !== '') {
          requestBodyUpdate.description = Description;
        }
        if (ids.length>0) {
          requestBodyUpdate.imageIds = ids;
        }
        if (CIids.length>0) {
          requestBodyUpdate.categoryItemIds = CIids;
        }
 
        if (SCreationStatus=='AwaitingApproval')
        {
          requestBodyUpdate.action = 'CancelSubmission'; 
        }
 
        if (SCreationStatus=='Draft')
        {
          requestBodyUpdate.action = actions;
        }
         
        if (SCreationStatus=='ChangeRequested' && (actions === 'SaveAsDraft' || actions === 'Submit'))
        { 
            requestBodyUpdate.action = actions;
        }
         
       console.log(JSON.stringify(requestBodyUpdate));

        try {
          const response =  fetch(`${configData.apiUrl}Creation/UpdateCreation`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': token 
            },
            body: JSON.stringify(requestBodyUpdate),
          }).then((response) => response.json());
          if (response) 
          console.log(JSON.stringify(requestBodyUpdate));
        } 
        catch (error) {
          console.error('', error); 
          
        }; 
     }
    
      navigate('/MyCreation', { state: { refreshState:true, isTitle, isMessage , isStatus } });
      
    };
  
    const renderAllData = () => {
      let filteredImages = combinedImages;
    
      if (dataAllDraft && dataAllDraft.images) {
        filteredImages = dataAllDraft.images;
      }
    
      if (Array.isArray(filteredImages)) {
        return (
          <>
            {filteredImages.map((image) => {
              let path = '';
              if (image.url) {
                const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                const startIndex = image.url.indexOf(baseUrl);
                if (startIndex !== -1) {
                  path = configData.imageUrl + image.url.substring(baseUrl.length) + '/w=250';
                }
              }
             let imagenoUrl='https://imagedelivery.net/yJ5Gl_l36RVeosvhpEjUNw/'

              const shouldUseBase64 =
                ['Draft', 'AwaitingApproval', 'ChangeRequested', 'Approved'].includes(SCreationStatus) &&
                !image.srcImage;
    
              let imageSrc =  "";

              if (shouldUseBase64 && image.url) imageSrc = path;
              else {
                imageSrc=image.srcImage;
              }
            
              const imageAlt = shouldUseBase64 ? image.tag : `Image ${image.id}`;
    
              return (
                <div className="photos_favorite" key={image.id}>
                  <img
                    src={imageSrc} 
                    id={image.id}
                    alt={imageAlt}
                    onClick={() => openModalImage(image.id)}
                  />
                </div>
              );
            })}
    
            {/* Modal Component */}
            {isModalOpenImage && (
              <Modal
                isOpen={isModalOpenImage}
                onRequestClose={() => setisModalOpenImage(false)}
                contentLabel="Image Modal"
                className="modal"
                overlayClassName="overlay"
              >
                <ImagePopup
                  imageId={isModalImageId}
                  isPopupOpen={isModalOpenImage}
                  closePopup={() => setisModalOpenImage(false)}
                  CollectionType={combinedImages.find((img) => img.id === isModalImageId)?.CollectionType}
                  Status={'Details'}
                />
              </Modal>
            )}
          </>
        );
      }
    
      return null; // Eğer `filteredImages` bir dizi değilse, hiçbir şey döndürülmez.
    };
    
    
  return (
    <div className='content'>
      <div className='MyStudioContent'>
        { SCreationStatus=== 'edit_mystudio'  && editcreationStatus!=='' ? (
        <div className='navbar'> 
          <button onClick={handleMystudioClick} className='showAll_button'>
            <div className='button_createpost'>
              <div className='button_back_appIcon'>
                <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 35 36" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M16.9874 26.2374C16.304 26.9209 15.196 26.9209 14.5126 26.2374L7.51256 19.2374C6.82915 18.554 6.82915 17.446 7.51256 16.7626L14.5126 9.76256C15.196 9.07915 16.304 9.07915 16.9874 9.76256C17.6709 10.446 17.6709 11.554 16.9874 12.2374L12.9749 16.25L26.25 16.25C27.2165 16.25 28 17.0335 28 18C28 18.9665 27.2165 19.75 26.25 19.75H12.9749L16.9874 23.7626C17.6709 24.446 17.6709 25.554 16.9874 26.2374Z" fill="white"/>
                </svg>
              </div>
              <div className='button_createpost_label'>
                My Studio
              </div>
            </div>
          </button>
          <div className='mystudioDetails_right'>
            <button onClick={handleSubmitClick('Submit')} className='showAll_button'>
              <div className='button_myposts_creations'>
                <div className='button_myposts_creations_label'>
                  Submit
                </div>
                <div className='button_submit_appIcon'>
                  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 31 30" fill="none">
                    <path d="M27 15C27 21.3513 21.8513 26.5 15.5 26.5C9.14873 26.5 4 21.3513 4 15C4 8.64873 9.14873 3.5 15.5 3.5C21.8513 3.5 27 8.64873 27 15ZM21.4142 13.4142C22.1953 12.6332 22.1953 11.3668 21.4142 10.5858C20.6332 9.80474 19.3668 9.80474 18.5858 10.5858L14 15.1716L12.4142 13.5858C11.6332 12.8047 10.3668 12.8047 9.58579 13.5858C8.80474 14.3668 8.80474 15.6332 9.58579 16.4142L12.5858 19.4142C13.3668 20.1953 14.6332 20.1953 15.4142 19.4142L21.4142 13.4142Z" fill="#FEF6F6" stroke="white"/>
                  </svg>
                </div>
              </div>
            </button>
            <button onClick={handleSubmitClick('SaveAsDraft')} className='showAll_button'>
              <div className='mystudioDetails_right_saveDraft'>
                <div className='button_myposts_creations_label'>
                  Save as draft
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.69995 4.70001C4.69995 3.54021 5.64015 2.60001 6.79995 2.60001H11.615C12.172 2.60001 12.7061 2.82126 13.1 3.21508L16.6849 6.80001C17.0787 7.19383 17.3 7.72798 17.3 8.28493V17.3C17.3 18.4598 16.3597 19.4 15.2 19.4H6.79995C5.64015 19.4 4.69995 18.4598 4.69995 17.3V4.70001ZM6.79995 11C6.79995 10.4201 7.27005 9.95001 7.84995 9.95001H14.15C14.7298 9.95001 15.2 10.4201 15.2 11C15.2 11.5799 14.7298 12.05 14.15 12.05H7.84995C7.27005 12.05 6.79995 11.5799 6.79995 11ZM7.84995 14.15C7.27005 14.15 6.79995 14.6201 6.79995 15.2C6.79995 15.7799 7.27005 16.25 7.84995 16.25H14.15C14.7298 16.25 15.2 15.7799 15.2 15.2C15.2 14.6201 14.7298 14.15 14.15 14.15H7.84995Z" stroke="white" strokeLinejoin="round"/>
                  </svg>
                </div>
              </div>
            </button>
          </div>
        </div>
      ) :
 
        ( <div className='navbar'> 
            <button onClick={handleMyCreationClick} className='showAll_button'>
              <div className='button_createpost'>
                <div className='button_back_appIcon'>
                  <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 35 36" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M16.9874 26.2374C16.304 26.9209 15.196 26.9209 14.5126 26.2374L7.51256 19.2374C6.82915 18.554 6.82915 17.446 7.51256 16.7626L14.5126 9.76256C15.196 9.07915 16.304 9.07915 16.9874 9.76256C17.6709 10.446 17.6709 11.554 16.9874 12.2374L12.9749 16.25L26.25 16.25C27.2165 16.25 28 17.0335 28 18C28 18.9665 27.2165 19.75 26.25 19.75H12.9749L16.9874 23.7626C17.6709 24.446 17.6709 25.554 16.9874 26.2374Z" fill="white"/>
                  </svg>
                </div>
                <div className='button_createpost_label'>
                  My Creation
                </div>
              </div>
            </button> 
    
          { SCreationStatus=== 'AwaitingApproval'  && (
              <div className='mystudioDetails_right'>
                <button onClick={handleSubmitClick('SaveAsDraft')} className='showAll_button'>
                  <div className='button_myposts_creations'>
                    <div className='button_myposts_creations_label'>
                      Cancel submission
                    </div>
                    <div>
                        <div className='button_cancel_appIcon'>
                          <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 30 29" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 29C23.0081 29 29.5 22.5081 29.5 14.5C29.5 6.49187 23.0081 0 15 0C6.99187 0 0.5 6.49187 0.5 14.5C0.5 22.5081 6.99187 29 15 29ZM21.9513 18.6011C22.688 19.348 22.688 20.6448 21.9202 21.4125C21.142 22.1906 19.845 22.1906 19.0979 21.4436L14.9993 17.3458L10.8904 21.454C10.1848 22.1698 8.83592 22.2009 8.06809 21.4229C7.30026 20.6552 7.33139 19.3169 8.03696 18.6114L12.1459 14.4929L8.03696 10.395C7.33139 9.68956 7.30026 8.3409 8.06809 7.57321C8.83592 6.79513 10.1848 6.83663 10.8904 7.54208L14.9993 11.6399L19.0979 7.55246C19.845 6.81588 21.142 6.80551 21.9202 7.58358C22.688 8.35128 22.688 9.65844 21.9513 10.4054L17.8527 14.4929L21.9513 18.6011Z" fill="white"/>
                          </svg>
                        </div>
                      </div>
                  </div>
                </button>
              
              </div>
           )} 
             { SCreationStatus=== 'ChangeRequested' && (
                 <div className='mystudioDetails_right'>
                    <button onClick={handleSubmitClick('Submit')} className='showAll_button'>
                      <div className='button_myposts_creations'>
                        <div className='button_myposts_creations_label'>
                          Submit
                        </div>
                        <div className='button_submit_appIcon'>
                          <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 31 30" fill="none">
                            <path d="M27 15C27 21.3513 21.8513 26.5 15.5 26.5C9.14873 26.5 4 21.3513 4 15C4 8.64873 9.14873 3.5 15.5 3.5C21.8513 3.5 27 8.64873 27 15ZM21.4142 13.4142C22.1953 12.6332 22.1953 11.3668 21.4142 10.5858C20.6332 9.80474 19.3668 9.80474 18.5858 10.5858L14 15.1716L12.4142 13.5858C11.6332 12.8047 10.3668 12.8047 9.58579 13.5858C8.80474 14.3668 8.80474 15.6332 9.58579 16.4142L12.5858 19.4142C13.3668 20.1953 14.6332 20.1953 15.4142 19.4142L21.4142 13.4142Z" fill="#FEF6F6" stroke="white"/>
                          </svg>
                        </div>
                      </div>
                    </button>
                    <button onClick={handleSubmitClick('SaveAsDraft')} className='showAll_button'>
                      <div className='mystudioDetails_right_saveDraft'>
                        <div className='button_myposts_creations_label'>
                          Save as draft
                        </div>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.69995 4.70001C4.69995 3.54021 5.64015 2.60001 6.79995 2.60001H11.615C12.172 2.60001 12.7061 2.82126 13.1 3.21508L16.6849 6.80001C17.0787 7.19383 17.3 7.72798 17.3 8.28493V17.3C17.3 18.4598 16.3597 19.4 15.2 19.4H6.79995C5.64015 19.4 4.69995 18.4598 4.69995 17.3V4.70001ZM6.79995 11C6.79995 10.4201 7.27005 9.95001 7.84995 9.95001H14.15C14.7298 9.95001 15.2 10.4201 15.2 11C15.2 11.5799 14.7298 12.05 14.15 12.05H7.84995C7.27005 12.05 6.79995 11.5799 6.79995 11ZM7.84995 14.15C7.27005 14.15 6.79995 14.6201 6.79995 15.2C6.79995 15.7799 7.27005 16.25 7.84995 16.25H14.15C14.7298 16.25 15.2 15.7799 15.2 15.2C15.2 14.6201 14.7298 14.15 14.15 14.15H7.84995Z" stroke="white" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      </div>
                    </button>
                  </div>
              )}
              { SCreationStatus=== 'Draft' && (
                <div className='mystudioDetails_right'>
                  <button onClick={handleSubmitClick('Submit')} className='showAll_button'>
                    <div className='button_myposts_creations'>
                      <div className='button_myposts_creations_label'>
                        Submit
                      </div>
                      <div className='button_submit_appIcon'>
                        <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 31 30" fill="none">
                          <path d="M27 15C27 21.3513 21.8513 26.5 15.5 26.5C9.14873 26.5 4 21.3513 4 15C4 8.64873 9.14873 3.5 15.5 3.5C21.8513 3.5 27 8.64873 27 15ZM21.4142 13.4142C22.1953 12.6332 22.1953 11.3668 21.4142 10.5858C20.6332 9.80474 19.3668 9.80474 18.5858 10.5858L14 15.1716L12.4142 13.5858C11.6332 12.8047 10.3668 12.8047 9.58579 13.5858C8.80474 14.3668 8.80474 15.6332 9.58579 16.4142L12.5858 19.4142C13.3668 20.1953 14.6332 20.1953 15.4142 19.4142L21.4142 13.4142Z" fill="#FEF6F6" stroke="white"/>
                        </svg>
                      </div>
                    </div>
                  </button>
                  <button onClick={openModal} className='showAll_button'>
                      <div className='button_myposts_creations'>
                      <div className='button_delete_appIcon'>
                            <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 29 32" fill="none">
                              <path d="M25.778 7.94444L24.3806 27.5074C24.2602 29.1936 22.8571 30.5 21.1666 30.5H7.83382C6.14332 30.5 4.74023 29.1936 4.61979 27.5074L3.22244 7.94444M11.278 14.3889V24.0556M17.7224 14.3889V24.0556M19.3335 7.94444V3.11111C19.3335 2.22132 18.6122 1.5 17.7224 1.5H11.278C10.3882 1.5 9.66688 2.22132 9.66688 3.11111V7.94444M1.61133 7.94444H27.3891" stroke="#FF453A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          </div>
                          <div className='button_myposts_creations_label'>
                            Delete 
                          </div> 
                      </div>
                  </button> 
                  <button onClick={handleSubmitClick('SaveAsDraft')} className='showAll_button'>
                      <div className='mystudioDetails_right_saveDraft'>
                        <div className='button_myposts_creations_label'>
                          Save as draft
                        </div>
                        <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.69995 4.70001C4.69995 3.54021 5.64015 2.60001 6.79995 2.60001H11.615C12.172 2.60001 12.7061 2.82126 13.1 3.21508L16.6849 6.80001C17.0787 7.19383 17.3 7.72798 17.3 8.28493V17.3C17.3 18.4598 16.3597 19.4 15.2 19.4H6.79995C5.64015 19.4 4.69995 18.4598 4.69995 17.3V4.70001ZM6.79995 11C6.79995 10.4201 7.27005 9.95001 7.84995 9.95001H14.15C14.7298 9.95001 15.2 10.4201 15.2 11C15.2 11.5799 14.7298 12.05 14.15 12.05H7.84995C7.27005 12.05 6.79995 11.5799 6.79995 11ZM7.84995 14.15C7.27005 14.15 6.79995 14.6201 6.79995 15.2C6.79995 15.7799 7.27005 16.25 7.84995 16.25H14.15C14.7298 16.25 15.2 15.7799 15.2 15.2C15.2 14.6201 14.7298 14.15 14.15 14.15H7.84995Z" stroke="white" strokeLinejoin="round"/>
                          </svg>
                        </div>
                      </div>
                  </button>
                </div>
              )}
              
              <Modal isOpen={IsModalOpen}
                onRequestClose={closeModal}
                contentLabel="Delete Creation"
                className="modal"
                overlayClassName="overlay"
                >
                  <Alert 
                      isPopupOpen={openModal}
                      closePopup={closeModal} 
                      title={isTitleDelete}
                      message={isMessageDelete}
                      status={'Delete'}
                      creationId ={creationId}
                    />
              </Modal>
        </div>
        ) }
        { SCreationStatus!== 'edit_mystudio' && 
          <div className='statusMyCreation'>
                {SCreationStatus}
          </div>
        }
          
          <div className='mystudioDetails_table'> 
            <div className='mystudioDetails_titlebar'> 

              <div className='mystudioDetails_title'>
                FILL OUT THE FIELDS
              </div> 
                { SCreationStatus === 'ChangeRequested' && (
                  <div className='requestBar'>
                    <span className='requestText'>REQUESTED CHANGES </span>
                      <div className='requestBar_big_cr'>
                        <div className='requestBar_big_textcr'>  
                          <textarea 
                            name="changeRequestContent"     
                            className='requestBar_big_textcr' 
                            placeholder='change request note'
                            disabled
                            value={savedRequest}
                            />  
                        </div> 
                      </div>  
                  </div>)}
            </div> 
          <div className='mystudioDetails_table_left'> 
            <div className='table_left_textfield'>  
              <div className='textfield_text'> 
                <input type='text' placeholder='Title. Max 50 character' value={Title}
                  onChange={handleTitleChange} disabled={SCreationStatus === 'AwaitingApproval' || SCreationStatus === 'Approved'} maxLength={50} />    
              </div> 
              <div className='alertmessage'>{!isTitleValid && <div className='error-message'> !!! </div>}
              </div>
              <button onClick={clearText} className='showAll_button'>
                <div className='textfield_clearbuttonbox'> 
                  <div className='textfield_clearbutton'> 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
                      <path d="M6.5 18L18.5 6M6.5 6L18.5 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>

            <div className='selectbuttons'>
             <div className={`category_select ${selectedCategory ? 'selected' : ''}`}>
                <select onChange={handleCategoryChange}  className='category_select' id='categorySelect'
                 value={selectedCategory}  disabled={SCreationStatus === 'AwaitingApproval' || SCreationStatus === 'Approved'}>
                  <option value="">Category </option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id} className='option'>
                      {category.name}
                    </option>
                  ))}
                </select>
                {!isCategoryValid && <div className='error-message'> !!!</div>}
              </div>
              <div className={`category_select ${selectedColor ? 'selected' : ''}`}>
                <select onChange={handleColorChange}
                 value={selectedColor} disabled={SCreationStatus === 'AwaitingApproval' || SCreationStatus === 'Approved'}>
                    <option value="">Color</option>
                    {colors.map(color => (
                      <option key={color.id} value={color.id}>
                        {color.name}
                      </option>
                    ))}
                  </select>    
                  {!isColorValid && <div className='error-message'> !!!</div>}
              </div>
              <div className={`category_select ${selectedDesignedFor ? 'selected' : ''}`}>
                <select onChange={handleDesignedForChange}     
                 value={selectedDesignedFor} disabled={SCreationStatus === 'AwaitingApproval' || SCreationStatus === 'Approved'}>
                    <option value="">Designed For</option>
                    {designedFor.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {!isDesignedForValid && <div className='error-message'> !!!</div>}
              </div>
            </div>

            
            <div className='textfield_big'>
              <div className='alertmessage'>{!isDescriptionValid && <div className='error-message'> !!! </div>}
              </div>
              <div className='textfield_big_text'>
                <textarea 
                  name="postContent"    
                  placeholder='Description of the piece (inspiration, aesthetic, storytelling, etc). Max. 500 char.'
                  className='textfield_big_text' 
                  value={Description}   
                  onChange={handleDescriptionChange} disabled={SCreationStatus === 'AwaitingApproval' || SCreationStatus === 'Approved'}
                />  
                <div>  {Description &&  (Description.length /maxLength)}
                </div>
              </div>
             
            </div>
          </div>
          
          <div className='mystudioDetails_table_right'> 
            <div className='mystudioDetails_table_header'> 
              <div className='mystudioDetails_table_header_titlebox'> 
                <div className='table_header_title'> 
                  SELECTED ({imagecounts})
                </div>  
                <div className='table_header_description'> 
                  Drag the files to change its order. First file will appear as the cover photo. 
                </div>  
              </div>
            </div>
          
            <div className='mystudioDetails_table_AI_Preview'>
              <div className='mystudioDetails_table_corner_rounding'>  
                    {renderAllData()} 
              </div>
            </div>
            { SCreationStatus!== 'AwaitingApproval' && SCreationStatus!== 'Approved' && (
              <button onClick={handleEditSelectionClick} className='showAll_button'>
                <div className='mystudioDetails_table_buttonbox'>
                  <div className='mystudioDetails_table_buttonAppIcon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                      <path d="M18.875 11C18.875 15.3492 15.3492 18.875 11 18.875C6.65076 18.875 3.125 15.3492 3.125 11C3.125 6.65076 6.65076 3.125 11 3.125C15.3492 3.125 18.875 6.65076 18.875 11Z" fill="#555555"/>
                      <path d="M7.5 11H7.50875M11 11H11.0088M14.5 11H14.5088M18.875 11C18.875 15.3492 15.3492 18.875 11 18.875C6.65076 18.875 3.125 15.3492 3.125 11C3.125 6.65076 6.65076 3.125 11 3.125C15.3492 3.125 18.875 6.65076 18.875 11Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                
                  <div className='mystudioDetails_table_buttonText'>
                    Edit selection
                  </div>
                </div>
              </button>  
            )} 
          </div>
        </div>
      </div>
    </div>
  );
};
