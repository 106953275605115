import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useData } from '../config/DataContext';
import '../styles/uploadPopup.css';

const FileUpload = forwardRef(({ isPopupOpen, closePopup, currentFileCount }, ref) => {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);  
  const configData = useData();
  let token = localStorage.getItem('Authorization');

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    const oversizedFiles = [];
    const disallowedFiles = []; // To track invalid files
  
    const maxFileCount = 50;
    const allowedTypes = ["image/jpeg", "image/png", "image/heic", "image/jpg"];
  
    selectedFiles.forEach(file => {
      if (!allowedTypes.includes(file.type)) {
        disallowedFiles.push(file.name); // Save unsupported file names
      } else if (file.size > 5 * 1024 * 1024) {
        oversizedFiles.push(file.name);
      } else {
        validFiles.push(file);
      }
    });
  
    if (disallowedFiles.length > 0) {
      setErrorMessage(`The following files have unsupported types and will not be uploaded: ${disallowedFiles.join(', ')}`);
    } else if (currentFileCount + validFiles.length > maxFileCount) {
      setErrorMessage(`The total number of files exceeds 50. Please remove some files before uploading.`);
      setFiles([]);
    } else if (oversizedFiles.length > 0) {
      setErrorMessage(`The following files are larger than 5 MB and will not be uploaded: ${oversizedFiles.join(', ')}`);
      setFiles(validFiles.slice(0, maxFileCount - currentFileCount));
      convertFilesToBase64(validFiles.slice(0, maxFileCount - currentFileCount));
    } else {
      setErrorMessage('');
      setFiles(validFiles.slice(0, maxFileCount - currentFileCount));
      convertFilesToBase64(validFiles.slice(0, maxFileCount - currentFileCount));
    }
  };
  
  

  const convertFilesToBase64 = (files) => {
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve({ name: file.name, type: file.type, data: reader.result.split(',')[1] });
        reader.onerror = (error) => reject(error);
      });
    });

    Promise.all(promises)
      .then((base64Files) => setFiles(base64Files))
      .catch((error) => console.error('Error converting files to base64', error));
  };

  useImperativeHandle(ref, () => ({
    handleUpload
  }));

  const [customFields, setCustomFields] = useState([]);
  const handleUpload = async () => {
    setLoading(true);  

    const fileMetadata = files.map(file => {
      const metadataWithCustomFields = {
        userId: 1,
        content: file.data,
        uploadFileName: file.name,
        type: file.type.split('/')[1],
        tag: '',
      };

      customFields.forEach(field => {
        if (field.name && field.value) {
          metadataWithCustomFields[field.name] = field.value;
        }
      });

      return metadataWithCustomFields;
    });

    const requestBody = JSON.stringify(fileMetadata);

    try {
      const response = await fetch(`${configData.apiUrl}User/AddCustomImages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: requestBody,
      });

      if (response.ok) {
        console.log('Files uploaded successfully');
        closePopup(true);
      } else {
        console.error('Error uploading files');
      }
    } catch (error) {
      console.error('Error uploading files', error);
    } finally {
      setLoading(false);  
    }
  };

  if (!isPopupOpen) return null;

  return (
    <div className="uploadBack">
      <div className="uploadBody">
        <button onClick={closePopup} className='showAll_button'>
          <div className="uploadappIcon">
            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none">
              <path d="M28.1667 35.1667H26V26.5H23.8333M26 17.8333H26.0217M45.5 26.5C45.5 37.2696 36.7696 46 26 46C15.2304 46 6.5 37.2696 6.5 26.5C6.5 15.7304 15.2304 7 26 7C36.7696 7 45.5 15.7304 45.5 26.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </button>
        <div className='uploadContent'>
          <div className="uploadTitle">UPLOAD CONTENT</div>
          <div className='uploadText'>Storage limit of 50 files. Maximum file size of 5 MB.</div>
          {errorMessage && <div className="errorMessage">{errorMessage}</div>}
          <div className='uploadSeperator'>
            <div className='uploadSeperator_Sub' />
          </div>
          <div className='uploadfileinput'>
            <input type="file" multiple onChange={handleFileChange} className='uploadfileinput'
             accept="image/jpeg, image/png, image/heic, image/jpg" />
          </div>
          {!errorMessage && (
            <button
              onClick={handleUpload}
              disabled={loading}
              className={`showAll_button ${loading ? 'disabled' : ''}`}
            >
              <div className='uploadButton'>
                <div className='uploadButtonText'>{loading ? 'Uploading...' : 'Upload'}</div>
                <div className='uploadButtonappIcon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
                    <path d="M6.41667 15.1668C4.39162 15.1668 2.75 13.5252 2.75 11.5002C2.75 9.75203 3.97335 8.28963 5.61065 7.92237C5.53822 7.59878 5.5 7.26227 5.5 6.91683C5.5 4.38552 7.55203 2.3335 10.0833 2.3335C12.301 2.3335 14.1508 3.90856 14.5752 6.00106C14.6056 6.00046 14.6361 6.00016 14.6667 6.00016C17.198 6.00016 19.25 8.05219 19.25 10.5835C19.25 12.8009 17.6754 14.6505 15.5833 15.0751M13.75 12.4168L11 9.66683M11 9.66683L8.25 12.4168M11 9.66683L11 20.6668" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default FileUpload;
