import React, { createContext, useState, useContext } from 'react';

// Context oluşturulması
const DesignContext = createContext();

// Context'i kullanacak olan bileşenler
export const useDesign = () => {
    const context = useContext(DesignContext);
    if (context === undefined) {
      throw new Error('useDesign must be used within a DesignProvider');
    }
    return context;
  };
  
// Context sağlayıcı bileşeni
export const DesignProvider = ({ children }) => {
  const [designId, setDesignId] = useState(null);
  const [balance, setTokenBalance] = useState(null);

  const setDesignData = (data) => {
    setDesignId(data.designId);
    setTokenBalance(data.tokenBalance);
  };

  return (
    <DesignContext.Provider value={{ designId, balance, setDesignData }}>
      {children}
    </DesignContext.Provider>
  );
};