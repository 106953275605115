import React, { useEffect, useState, useRef } from 'react';
import Header from '../components/Header';
import GetTokenBalance from '../components/GetTokenBalance';
import { useBalance } from '../components/BalanceContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useData } from '../config/DataContext';  
import '../styles/figma.css';
import '../styles/figma_min.css';
import '../styles/multiselect-custom.css';
import logo from '../images/Logo.png';
import userSvg from '../images/user.svg';

const MainLayout = ({ children }) => {
    const { tokenBalance } = useBalance();
    const [sticky, setSticky] = useState(false);
    const headerRef = useRef(null);
    const rolstatus = 'User';
    const location = useLocation();
    const isAIDesignPath = location.pathname === '/AIDesign';
    const isLogin = location.pathname === '/Login';
    const isSpotLightPath = location.pathname.endsWith('/spotlight') || location.pathname === '/SpotLight';
    const [userPhoto, setUserPhoto] = useState(null); 
    const configData = useData();

    const navigate = useNavigate();
    let userType = localStorage.getItem('userType');  

    useEffect(() => {
        const fetchUserPhoto = () => {
            let storedPhoto = localStorage.getItem('userPhoto');
            if (storedPhoto) {
                try {
                    storedPhoto = JSON.parse(storedPhoto);
                    setUserPhoto(storedPhoto);
                } catch (error) {
                    console.log('Error parsing user photo:', error);
                    setUserPhoto(null);
                }
            }
        };
        
       
        fetchUserPhoto();

        const token = localStorage.getItem('Authorization');
        if (!token && location.pathname !== '/Login') {
            localStorage.setItem('redirectPath', location.pathname);
            navigate('/Login');
        }
    }, [navigate, location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current) {
                const headerOffset = headerRef.current.offsetTop;
                if (window.pageYOffset > headerOffset) {
                    setSticky(true);
                } else {
                    setSticky(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLinkClick = (path) => {
        window.location.href = path;
    }
    
    return (
        <div className={isSpotLightPath ? 'defaultwindowSpotLight' : 'defaultwindow'}>
            <div className={isSpotLightPath ? 'windowSpotLight' : 'window'}>
                {isAIDesignPath && userType === '1' && (
                    <div className="buttons">
                        <div className='button_symbol'>
                            <span className='symbol'>
                                <GetTokenBalance />
                                {(tokenBalance !== null) ? (
                                    <div>{tokenBalance} </div>
                                ) : (
                                    <div>0 </div>
                                )}
                            </span>
                        </div>
                        <div className='icon_coin' onClick={() => handleLinkClick("https://shop.getyouniq.com/products/ai-credits")}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 45" fill="none">
                                <path d="M6.6001 26.9V33.5C6.6001 37.1451 13.4949 40.1 22.0001 40.1C30.5053 40.1 37.4001 37.1451 37.4001 33.5V26.9C37.4001 30.5451 30.5053 33.5 22.0001 33.5C13.4949 33.5 6.6001 30.5451 6.6001 26.9Z" fill="#FEF6F6" />
                                <path d="M6.6001 15.9V22.5C6.6001 26.1451 13.4949 29.1 22.0001 29.1C30.5053 29.1 37.4001 26.1451 37.4001 22.5V15.9C37.4001 19.5451 30.5053 22.5 22.0001 22.5C13.4949 22.5 6.6001 19.5451 6.6001 15.9Z" fill="#FEF6F6" />
                                <path d="M37.4001 11.5C37.4001 15.1451 30.5053 18.1 22.0001 18.1C13.4949 18.1 6.6001 15.1451 6.6001 11.5C6.6001 7.85491 13.4949 4.89999 22.0001 4.89999C30.5053 4.89999 37.4001 7.85491 37.4001 11.5Z" fill="#FEF6F6" />
                            </svg>
                        </div>
                    </div>
                )}

                {!isAIDesignPath && userType === '1' && isLogin===false && (
                    <div className="buttons">      
                    <div className='icons_avatar'>
                    {userPhoto ? (
                      (() => {
                        let path = '';
                        if (userPhoto.url) {
                          const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                          const startIndex = userPhoto.url.indexOf(baseUrl);
                          if (startIndex !== -1) {
                            path = configData.imageUrl + userPhoto.url.substring(baseUrl.length) + '/w=250';
                          }
                        }

                        return (
                        <>
                          {path ? (<img
                            src={path}    className='userprofileFoto_small'
                          />) : <img src={userSvg}   className='userprofileFoto_small' />}
                        </>
                        );
                      })( <img src={userSvg}   className='userprofileFoto_small' />)
                    ) :  <img src={userSvg}   className='userprofileFoto_small' />}
 
                     </div> 
                    </div>
                )}

 
                
                {!isSpotLightPath ? (
                    <header id="header"><Header rol={rolstatus} /></header>
                ) : (
                    <Header rol={rolstatus} />
                )}  

                <div className='chat_table'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default MainLayout;
