import { format } from 'date-fns';

const FormatDate = (dateString) => {
  const date = new Date(dateString);

  // Format the date part as MM/dd/yy
  const formattedDate = format(date, 'MM/dd/yy');

  // Get hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes();
  
  // Determine AM/PM
  const ampm = hours >= 12 ? 'p.m.' : 'a.m.';
  
  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  
  // Format minutes to always be two digits
  const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

  // Combine parts into final format
  const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;

  return `${formattedDate}, ${formattedTime}`;
};

export default FormatDate;
