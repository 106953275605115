import React, {useEffect} from 'react'; 
import { useNavigate } from 'react-router-dom';  
import '../styles/menu.css';
import userSvg from '../images/user.svg';
import { useData } from '../config/DataContext';  

const Menu = ({ isPopupOpen, closePopup }) => {
  const navigate = useNavigate();  
  const configData = useData();
  let userType = localStorage.getItem('userType'); 
  let userName = localStorage.getItem('userName');
  
  let userPhoto = localStorage.getItem('userPhoto');

  let token = localStorage.getItem('Authorization');

  if (userPhoto) {
      try {
          userPhoto = JSON.parse(userPhoto);  
      } catch (error) {
          console.log('Error parsing user photo:', error);
          userPhoto = null; 
      }
  }
  

  const handleOverlayClick = (e) => {
   
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };
   
 const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

 const handleLinkClick = (path) => {
  closePopup();

  const token = localStorage.getItem('Authorization'); // Fetch the token

  if (isMobile && window.ReactNativeWebView) {
    if (path === "/SpotLight") {
      const payload = JSON.stringify({ action: 'openNativeSpotlight', token, userPhoto, userName, userType });
      window.ReactNativeWebView.postMessage(payload); // Send the token with the action
    } else {
      navigate(path);
    }
  } else {
    navigate(path);
  }
};

  const logOut = () => {
    localStorage.clear();
    closePopup();
    navigate("/Login");
  };
  
  if (!isPopupOpen) return null;

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="content_menu">
       <div className="content_menu_sub"> 
          <div className="menu_header">    
            <div className="menu_section_header"> 
              <div className="menu_section_header_text">
                MENU
              </div>
            </div>
            <div className='menu_separator'/> 
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text"> 
                <button  className='item_button'>
                  <a href ="https://shop.getyouniq.com/collections/gallery"   >
                    Gallery
                  </a>
                </button>
          
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text"> 
                <button  className='item_button'>
                  <a href ="https://shop.getyouniq.com/pages/creators"   >
                    Creators
                  </a>
                </button>
               
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
                <button onClick={() => handleLinkClick("/SpotLight")} className='item_button'>Spotlight</button>
    
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/MyStudio")} className='item_button'>My Studio</button>
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/MyCreation")} className='item_button'>My Creations</button>
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/AIDesign")} className='item_button'>AI Design</button>
              </div>
            </div>
          </div>
          {userType === '2' && ( <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/CreationList")} className='item_button'>Creation List</button>
              </div>
            </div>
          </div>)}
       
        </div>

        <div className="userprofileItem">
          <div className="userprofileFoto">  
                  {userPhoto ? (
                      (() => {
                        let path = '';
                        if (userPhoto.url) {
                          const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                          const startIndex = userPhoto.url.indexOf(baseUrl);
                          if (startIndex !== -1) {
                            path = configData.imageUrl + userPhoto.url.substring(baseUrl.length) + '/w=250';
                          }
                        }

                        return (
                        <>
                          {path ? (<img
                            src={path}
                               className='userprofileFoto'
                          />) : ( <img src={userSvg}   className='userprofileFoto' />)}
                        </>
                        );
                      })( <img src={userSvg}   className='userprofileFoto' />)
                    ) :  <img src={userSvg}   className='userprofileFoto' />}
 
           </div>
            <div className="userprofileTitle">
              <div className="userprofileText">
                {userName}
              </div>
              <div className='logoutBlock' > <button onClick={() => logOut()} className='showAll_button   logoutBlock'>
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" 
              xmlns="http://www.w3.org/2000/svg"><path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 
              18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" 
              stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                 Logout</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
