import React, { useEffect, useState } from "react";

function ImageMagnifiers({
  src,
  width,
  height,
  magnifierHeight = 120,
  magnifierWidth = 120,
  zoomLevel = 2
}) {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [loadedImage, setLoadedImage] = useState(null);
  let status = localStorage.getItem('ImageStatus');

  useEffect(() => {
    
    const image = new Image();
    const statusValue = Number(status);
    image.src = statusValue === 1 ? `${src}/w=1024` : src;
    image.onload = () => setLoadedImage(image);
  }, [src]);

  if (!loadedImage) {
    return null;
  }

 /*const handleTouchMove = (e) => {
    const touch = e.touches[0]; // İlk dokunuşu alın
    const elem = e.currentTarget;
    const { top, left } = elem.getBoundingClientRect();
    const mouseX = touch.clientX - left;
    const mouseY = touch.clientY - top;

    // Sınırları kontrol et
    const posX = Math.max(magnifierWidth / 2, Math.min(mouseX, imgWidth - magnifierWidth / 2));
    const posY = Math.max(magnifierHeight / 2, Math.min(mouseY, imgHeight - magnifierHeight / 2));

    setXY([posX, posY]);
    setShowMagnifier(true);
  }; */

  const handleTouchEnd = () => {
    setShowMagnifier(false);  
  };

  return (
    <div
      style={{
        height: height,
        width: width,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <img
        {...(Number(status) === 1 && {
          src: `${src}/w=1024`,
          srcSet: `
            ${src + '/h=150'} 150h, 
            ${src + '/h=250'} 250h, 
            ${src + '/h=350'} 350h, 
            ${src + '/h=450'} 450h, 
            ${src + '/h=550'} 550h
          `,
          sizes: `(max-height: 150px) 150px, 
            (max-height: 250px) 250px, 
            (max-height: 350px) 350px, 
            (max-height: 450px) 450px, 
            (max-height: 550px) 550px`,
        })}
        {...(Number(status) !== 1 && {
          src: src,
        })}
        className="zoom-image"
        onMouseEnter={(e) => {
          const elem = e.currentTarget;
          const { width, height } = elem.getBoundingClientRect();
          setSize([width, height]);
          setShowMagnifier(true);
        }}
        onMouseMove={(e) => {
          const elem = e.currentTarget;
          const { top, left } = elem.getBoundingClientRect();
          const mouseX = e.pageX - left - window.pageXOffset;
          const mouseY = e.pageY - top - window.pageYOffset;

          const posX = Math.max(magnifierWidth / 2, Math.min(mouseX, imgWidth - magnifierWidth / 2));
          const posY = Math.max(magnifierHeight / 2, Math.min(mouseY, imgHeight - magnifierHeight / 2));

          setXY([posX, posY]);
        }}
        onMouseLeave={() => setShowMagnifier(false)}
       // onTouchMove={handleTouchMove}  Dokunma hareketi için
      //   onTouchEnd={handleTouchEnd} Dokunmayı bitirme
        alt="Zoomed"
      />

      {/* Magnifier */}
      <div
        style={{
          display: showMagnifier ? "block" : "none",
          position: "absolute",
          pointerEvents: "none",
          height: `${magnifierHeight}px`,
          width: `${magnifierWidth}px`,
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifierWidth / 2}px`,
          opacity: 1,
          border: "1px solid lightgray",
          backgroundColor: "white",
          backgroundImage: `url('${loadedImage.src}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,
          backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
          backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
        }}
      ></div>
    </div>
  );
}

export default ImageMagnifiers;
