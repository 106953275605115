import React, { createContext, useState, useContext } from 'react';

// Context oluşturulması
const BalanceContext = createContext();

// Context'i kullanacak olan bileşenler
export const useBalance= () => {
    const context = useContext(BalanceContext);
    if (context === undefined) {
      throw new Error('useDesign must be used within a DesignProvider');
    }
    return context;
  };
  
// Context sağlayıcı bileşeni
export const BalanceProvider = ({ children }) => { 
  const [tokenBalance, setBalance] = useState(null); 
  const setBalanceData = (data) => {   
    if (data.balance==undefined)
      setBalance(data.tokenBalance);
    else if ( (data.tokenBalance==undefined))
    setBalance(data.balance);
  };

  return (
    <BalanceContext.Provider value={{ tokenBalance, setBalanceData }}>
      {children}
    </BalanceContext.Provider>
  );
};