import React, { useEffect, useState } from 'react';
import { useData } from '../config/DataContext';
import { useBalance } from './BalanceContext';

const GetTokenBalance = () => {
  const [data, setData] = useState(null);
  const [userId, setuserId] = useState(1);
  const configData = useData();
  const { setBalanceData } = useBalance();
   
  let token = localStorage.getItem('Authorization');
  useEffect(() => { 
      fetch(configData.apiUrl + "Token/GetTokenBalance?userId=" + userId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token 
        }
      })
        .then(response => response.json())
        .then(data => {
          setData(data);
          setBalanceData(data); 
        })
        .catch(error => console.error('Error fetching data:', error));
    }, []); 
  return (
    <div>
   
    </div>
  ); 
};

export default GetTokenBalance;
