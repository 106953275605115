import React, { createContext, useContext } from 'react';
import { Data } from './ConfigData';

// Context oluşturma
const DataContext = createContext();

// Context Provider bileşeni
export const DataProvider = ({ children }) => {
  const data = Data;

  return (
    <DataContext.Provider value={data}>
      {children}
    </DataContext.Provider>
  );
};

// Context'i kullanmak için custom hook
export const useData = () => useContext(DataContext);