const SpinnerSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="100" viewBox="0 0 30 30" overflow="visible" fill="white" stroke="none">
    <defs>
    <circle id="loader" r="4" cx="50" cy="50" transform="translate(0 -30)"/>
    </defs>
    <use href="#loader" x="-6"><animate attributeName="opacity" values="0;1;0" dur="1s" begin="0.13s" repeatCount="indefinite">
    </animate>
    </use>
    <use  href="#loader" x="10">
    <animate attributeName="opacity" values="0;1;0" dur="1s" begin="0.25s" repeatCount="indefinite"></animate>
    </use>
    <use  href="#loader" x="26">
    <animate attributeName="opacity" values="0;1;0" dur="1s" begin="0.38s" repeatCount="indefinite"></animate>
    </use>
    <use  href="#loader" x="42"><animate attributeName="opacity" values="0;1;0" dur="1s" begin="0.50s" repeatCount="indefinite"></animate>
    </use>
    <use  href="#loader" x="58"><animate attributeName="opacity" values="0;1;0" dur="1s" begin="0.63s" repeatCount="indefinite"></animate>
    </use>  
  </svg>  
  );
  
export default SpinnerSVG;
  