import React, { useState, useEffect } from 'react'; 
import { useData } from '../config/DataContext'; 
import Modal from 'react-modal';
import ImagePopup from '../components/ImagePopup';
import { useLocation, useNavigate } from 'react-router-dom'; 
import userSvg from '../images/user.svg';
import heic2any from "heic2any";

export default function UserProfile() {
  return <MainComponent />;
}

const MainComponent = () => {  
  const [About, setAbout] = useState('');
  const [isAboutValid, setisAboutValid] = useState(true);
  const [FullName, setFullName] = useState(''); 
  const [isFullNameValid, setisFullNameValid] = useState(true);
  const [Email, setEmail] = useState(''); 
  const [isEmailValid, setisEmailValid] = useState(true);
  const [Page, setPage] = useState(''); 
  const [isPageValid, setisPageValid] = useState(true);
  const [userImage, setuserImage] = useState(''); 
  const [userImage2, setuserImage2] = useState(''); 
  const [files, setFiles] = useState([]); 
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate(); 
  const location = useLocation();
  const configData = useData(); 
  const maxLength = 500;
  const { creatorId, creator } = location.state || {};
  
  useEffect(() => {
    document.title = "YOUNIQ - User Profile";
  }, []);

  let token = localStorage.getItem('Authorization');
  useEffect(() => {
    if (creatorId) {
      getCreatorInfo(creatorId, 'Big');
    }
  }, [creatorId]);

  const handleChange = (status, event) => { 
    if (status =='Name') 
    {
        setFullName(event.target.value);
        setisFullNameValid(true);
    }
    else if (status =='Email') 
    {
        setEmail(event.target.value);
        setisEmailValid(true);
    }
    else if (status =='Page') 
    {
        setPage(event.target.value);
        setisPageValid(true);
    }
    else if (status == 'About')
    {  
        setAbout(event.target.value);
        setisAboutValid(true);
    }
  };

  const clearText = (status) => {
    if (status=='Name')
      setFullName(''); 
    else if (status=='Email')
      setEmail('');
    else if (status=='Page')
      setPage('');
  };

  const handleCreationClick = () => { 
    navigate(location.pathname, { replace: true, state: {} });
    navigate('/CreationList'); 
  };
  
  const getCreatorInfo = async (userId, platform) => {
    try { 
      const response = await fetch(
        `${configData.apiUrl}User/GetUserProfile?userId=${userId}&platform=${platform}&includeSpotlights=false`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
        }
      );
      const data = await response.json();      
      setFullName(data.name);
      setAbout(data.about);
      setPage(data.linkUrl);
      setuserImage(data.picture); 
     
      setEmail(data.email);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitClick = async () => { 
    if (FullName === '' || Page === '') { 
      setisFullNameValid(FullName !== '');
      setisPageValid(Page !== '');
      return;
    } 
    
    const requestBodyUpdate = {
      userId: creatorId,
      name : FullName,
      email : Email,
      about: About !== '' ? About : '',
      linkUrl: Page !== '' ? Page : '',
    };

    if (files.length > 0) {
      const [file] = files;
      requestBodyUpdate.picture = {
        content: file.data,
        mediaTypeName: file.type.split('/')[1],   
        uploadFileName: file.name.split('.')[0], 
      };
    }
    let modifierID=1;
    setIsLoading(true);

    //console.log(requestBodyUpdate)

    try {
      await fetch(`${configData.apiUrl}User/UpdateUserProfile?modifierId=${modifierID}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          'Authorization': token 
        },
        body: JSON.stringify(requestBodyUpdate),
      });
      // navigate('/CreationList', { state: { refresh: true } });
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

 const handleFileChange = (e) => {
  const file = e.target.files[0];

  if (!file) {
    console.error("No file detected!");
    return;
  }

  console.log("File Details:", file);

  if (file.size > 5 * 1024 * 1024) {
    setErrorMessage(`The selected file (${file.name}) is larger than 5 MB and will not be uploaded.`);
    setFiles([]);
    return;
  }

  // HEIC/HEIF gibi formatlar için MIME türü kontrolü
  const validMimeTypes = ["image/jpeg", "image/png", "image/heic", "image/heif"];
  if (!validMimeTypes.includes(file.type)) {
    setErrorMessage(`Unsupported file type: ${file.type}`);
    setFiles([]);
    return;
  }

  setErrorMessage("");
  convertFileToBase64(file);
};



  const convertFileToBase64 = (file) => {
   
    if (file.type === "image/HEIC" || file.type === "image/heif") {

      heic2any({ blob: file, toType: "image/jpeg" })
        .then((convertedBlob) => {
          const reader = new FileReader();
  
          reader.readAsDataURL(convertedBlob);
          reader.onload = () => {
            const base64File = {
              name: file.name,
              type: "image/jpeg", // Dönüştürülen dosya formatı
              data: reader.result.split(",")[1],
            };
  
            setFiles([base64File]);
            setuserImage2({
              content: base64File.data,
              mediaTypeName: "jpeg",
            });
          };
  
          reader.onerror = (error) => {
            console.error("Error reading converted file", error);
          };
        })
        .catch((error) => {
          console.error("Error converting HEIC/HEIF file", error);
        });
    } else {
      const reader = new FileReader();
  
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64File = {
          name: file.name,
          type: file.type,
          data: reader.result.split(",")[1],
        };
  
        setFiles([base64File]);
        setuserImage2({
          content: base64File.data,
          mediaTypeName: file.type.split("/")[1],
        });
      };
  
      reader.onerror = (error) => {
        console.error("Error converting file to base64", error);
      };
    }
  };
  
  return (
    <div className='content'>
      <div className='MyStudioContent'> 
        <div className='navbar'> 
          <button onClick={handleCreationClick} className='showAll_button'>
            <div className='button_createpost'> 
            <div className='button_back_appIcon'>
                <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 35 36" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M16.9874 26.2374C16.304 26.9209 15.196 26.9209 14.5126 26.2374L7.51256 19.2374C6.82915 18.554 6.82915 17.446 7.51256 16.7626L14.5126 9.76256C15.196 9.07915 16.304 9.07915 16.9874 9.76256C17.6709 10.446 17.6709 11.554 16.9874 12.2374L12.9749 16.25L26.25 16.25C27.2165 16.25 28 17.0335 28 18C28 18.9665 27.2165 19.75 26.25 19.75H12.9749L16.9874 23.7626C17.6709 24.446 17.6709 25.554 16.9874 26.2374Z" fill="white"/>
                </svg>
              </div>
              <div className='button_createpost_label'>
                ADMIN
              </div>
           
            </div>
          </button>
          <div className='mystudioDetails_right'>
            <button onClick={handleSubmitClick} className='showAll_button'>
              <div className='button_myposts_creations'>
                <div className='button_myposts_creations_label'>
                  Update
                </div>
                <div className='button_update_appIcon'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.69995 4.7001C4.69995 3.5403 5.64015 2.6001 6.79995 2.6001H11.615C12.172 2.6001 12.7061 2.82135 13.1 3.21517L16.6849 6.8001C17.0787 7.19392 17.3 7.72807 17.3 8.28502V17.3001C17.3 18.4599 16.3597 19.4001 15.2 19.4001H6.79995C5.64015 19.4001 4.69995 18.4599 4.69995 17.3001V4.7001ZM6.79995 11.0001C6.79995 10.4202 7.27005 9.9501 7.84995 9.9501H14.15C14.7298 9.9501 15.2 10.4202 15.2 11.0001C15.2 11.58 14.7298 12.0501 14.15 12.0501H7.84995C7.27005 12.0501 6.79995 11.58 6.79995 11.0001ZM7.84995 14.1501C7.27005 14.1501 6.79995 14.6202 6.79995 15.2001C6.79995 15.78 7.27005 16.2501 7.84995 16.2501H14.15C14.7298 16.2501 15.2 15.78 15.2 15.2001C15.2 14.6202 14.7298 14.1501 14.15 14.1501H7.84995Z" stroke="white" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            </button>
            
          </div>
        </div>
        <div className='profilePhotoBar'>
          <div className='profilePhotoSub'> 
                <div className='creatorImage' style={{ marginRight: 10 }}>
             {
                userImage2 ? (
                  <img
                    src={`data:image/${userImage2.mediaTypeName};base64,${userImage2.content}`}
                    alt="User Profile"
                    className="creatorImage"
                  />
                ) : userImage ? (
                  (() => {
                    let path = ''; 
                    if (userImage.url) {
                      
                      const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                      const startIndex = userImage.url.indexOf(baseUrl);
                      if (startIndex !== -1) {
                        path = configData.imageUrl + userImage.url.substring(baseUrl.length) + '/w=128';
                      }
                    }
                    return path ? (
                      <img
                        src={path}
                        alt="User Profile"
                        className="creatorImage"
                      />
                    ) : (
                      <img
                        src={userSvg}
                        alt="Default Profile"
                        className="creatorImage"
                      />
                    );
                  })()
                ) : (
                  <img
                    src={userSvg}
                    alt="Default Profile"
                    className="creatorImage"
                  />
                )
}

                 
                </div>
                <div className='profileTitleLink'>
                  <label htmlFor="file-upload" className="custom-file-upload">
                     Change profile picture
                  </label>
                  <input id="file-upload"  type="file"   onChange={handleFileChange}  />   
               
                  {errorMessage && <div className="errorMessage">{errorMessage}</div>}
                      
                </div>
                <div className='profileTitleText'>
                  {creator}
                </div> 
                    
          </div> 
        </div> 
        <div className='profileHeader'> 
             PROFILE INFORMATION
        </div>
        
        <div className='profileInformationTable'> 
           
          <div className='profileInformationTableLeft'>
            <div>
              <div className='profileInformationTableTitle'> 
                FULL NAME
              </div> 
              <div className='profileInformationTableLeftTextField'>  
                <div className='profiletextfield_text'> 
                  <input type='text' placeholder='Full Name' value={FullName} 
                   onChange={(e) => handleChange('Name', e)}
                />    
                </div> 
                <div className='alertmessage'>{!isFullNameValid && <div className='error-message'> !!! </div>}
                </div>
                <button  onClick={() => clearText('Name')}  className='showAll_button'>
                  <div className='textfield_clearbuttonbox'> 
                    <div className='textfield_clearbutton'> 
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
                        <path d="M6.5 18L18.5 6M6.5 6L18.5 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div>
              <div className='profileInformationTableTitle'> 
                EMAIL
              </div> 
              <div className='profileInformationTableLeftTextField'>  
                <div className='profiletextfield_text'> 
                  <input type='text' placeholder='E-mail' value={Email}
                   onChange={(e) => handleChange('Email', e)}
                  />    
                </div> 
                <div className='alertmessage'>{!isEmailValid && <div className='error-message'> !!! </div>}
                </div>
                <button  onClick={() => clearText('Email')}  className='showAll_button'>
                  <div className='textfield_clearbuttonbox'> 
                    <div className='textfield_clearbutton'> 
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
                        <path d="M6.5 18L18.5 6M6.5 6L18.5 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
            </div>

            <div>
              <div className='profileInformationTableTitle'> 
                PROFILE PAGE
              </div> 
              <div className='profileInformationTableLeftTextField'>  
                  <div className='profiletextfield_text'> 
                    <input type='text' placeholder='Profile Page' value={Page} 
                     onChange={(e) => handleChange('Page', e)}
                       />    
                  </div> 
                  <div className='alertmessage'>{!isPageValid && <div className='error-message'> !!! </div>}
                  </div>
                  <button onClick={() => clearText('Page')}  className='showAll_button'>
                    <div className='textfield_clearbuttonbox'> 
                      <div className='textfield_clearbutton'> 
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
                          <path d="M6.5 18L18.5 6M6.5 6L18.5 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                    </div>
                  </button>
              </div>
            </div>
          </div> 

          <div className='profileInformationTableRight'>
           
                <div className='profileInformationTableTitle'>
                  ABOUT
                </div>
                <div className='profileInformationTableRightTextField'>
                    <textarea     
                      placeholder='About' 
                      className='textfield_big_text_profile'
                      value={About}   
                      onChange={(e) => handleChange('About', e)}
                    />  
                </div> 
            </div>  
        </div>
        
      </div>
    </div>
  );
};
