import React, { useEffect, useState } from 'react'; 
import SpinnerSVG from './SpinnerSVG';
import { useData } from '../config/DataContext';
import { useDesign } from './DesignContext';
import { useBalance } from './BalanceContext';
import ImagePopup from './ImagePopup';
import Modal from 'react-modal';

const GenerateIteration=  ({DesignIDQuery,PromptQuery})  => {
    const [data, setData] = useState(null);   
    const [isVisible, setIsVisible] = useState(false);
    const configData = useData();
    const { setDesignData } = useDesign();
    const { setBalanceData } = useBalance();
    const [isModalOpenImage, setisModalOpenImage] = useState(false);
    const [isModalImageurl, setisModalImageurl] = useState(0); 
    let userName = localStorage.getItem('userName');
    useEffect(() => {
      if (DesignIDQuery && PromptQuery) { 
        fetchData();
      }
    }, [PromptQuery]);
    let token = localStorage.getItem('Authorization');
    const fetchData = () => {
      setIsVisible(true); 
          const response = fetch(`${configData.apiUrl}Design/GenerateIteration?designId=${DesignIDQuery}&prompt=${PromptQuery}`, {
                 method: 'POST',
                 headers: {
                   'Content-Type': 'application/json',
                   'Authorization': token 
                 },
               })
         .then((response) => response.json())
         .then((data) => {
           setData(data);
           setDesignData(data); 
           setBalanceData(data); 
           setIsVisible(false);
         
         })
         .catch((error) => {
           console.log(error);
           setIsVisible(false);
         });
     };
    
     const openModalImage = (url) => {
      let path = '';
      if (url) {
        const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
        const startIndex = url.indexOf(baseUrl);
        if (startIndex !== -1) {
          path = configData.imageUrl + url.substring(baseUrl.length) ;
        }
      }
      setisModalImageurl(path);
    
      setisModalOpenImage(true); 
    };

    const closeModalImage = (status,imageId) => {
      setisModalOpenImage(false);
      
    };

    return (  
        <div className='imagegenerated_main'> 
          {data ? (data.iterationImages.map((image, index) => (
            <div className='imagegenerated_main_sub'>
              <div className='messageia'> 
                  <div className='ainame_rowplain'> 
                        <div className='messageuser_image' />  
                        <div className='ainame'> {userName} </div> 
                  </div>
                  <div className='row_plain'> 
                        <div className='table_contents'>  
                          <div className='title_table_contents'>  
                              {PromptQuery}
                          </div> 
                        </div>
                  </div>

         
              </div> 
               <div className='messageuser'> 
                <div className='messageuser_row_plain'> 
                  <div className='messageuser_table_contents'> 
                    <div className='image_table_contents' />  
                      <div className='title_table_contents'>  
                      {image.revisedPrompt}
                      </div> 
                    </div>
                </div>
          
               </div>

              <div className='imagegenerated'>
                <div className='imagegenerated_aiimage'> 
                  <div className='imagegenerated_frame'> 
                    <div className='imagegenerated_rectangle'>  
                        {image ? (
                              (() => {
                                let path = '';
                                if (image.url) {
                                  const baseUrl = '//tech.getyouniq.com/cdn-cgi/imagedelivery/';
                                  const startIndex = image.url.indexOf(baseUrl);
                                  if (startIndex !== -1) {
                                    path = configData.imageUrl + image.url.substring(baseUrl.length) + '/w=480';
                                  }
                                }

                                return (
                                  <>
                                  <img src={path} 
                                  className='imagegenerated_imageai' alt={`${image.tag}`} 
                                  onClick={() => openModalImage(image.url)}  />    
                                  <Modal
                                  isOpen={isModalOpenImage}
                                  onRequestClose={closeModalImage}
                                  contentLabel="Upload Modal"
                                  className="modal"
                                  overlayClassName="overlay"
                                  >
                                  <ImagePopup
                                    isPopupOpen={isModalOpenImage}
                                    closePopup={closeModalImage}
                                    CollectionType={'CustomImages'}
                                    Status={'Details'}
                                    imageUrl = {isModalImageurl}
                                  />
                                </Modal> 
                                </>
                                );
                              })( )
                            ) :(<p></p>)}

                    </div>
                  </div> 
                </div>
                <div className='imagegenerated_createpost'>
                  Design saved in Creations. <a href={configData.webUrl + 'MyStudio'}>Create a post</a>
                </div>
              </div>
            </div>
                ))) : ( <div>   </div>) 
             }
            {isVisible && (
              <div className='messageuser'> 
                <div className='messageuser_row_plain'> 
                  <div className='messageuser_table_contents'> 
                    <div className='image_table_contents' />  
                      <div className='title_table_contents'> 
                         <SpinnerSVG /> 
                      </div>
                  </div>
                </div>
              </div> 
            )}
      </div>

    );   

  };
  
  export default GenerateIteration;

  
   